import app from '../common_app.es6';

app.directive('ngEscape', ['$parse', '$timeout', ($parse, $timeout) => {
  return function ($scope, $element, $attrs) {
    var fn = $parse($attrs.ngEscape);
    $element.bind("keydown keypress keyup", function (event) {
      if(event.which === 27) {
        $timeout(() => { fn($scope, { $event: event }); });
      }
    });
  };
}]);