import app from '../common_app.es6';
import d3 from '../d3.js';
import cloud from 'd3-cloud';
import { each, map, sortBy, reverse } from 'lodash/fp';

app.directive('wordCloud', ['FontLoader', (FontLoader) => {
  return {
    restrict: 'E',
    scope: {
      words: '=',
      noFill: '@',
      showProjectColor: '@',
      color: '@',
      onWordClick: '&',
      rangeMax: '@',
      rangeMin: '@',
      size: '=?',
      fontFamily: '@',
      cancelFontSetup: '@',
      padding: "@"
    },

    link: ($scope, $element, $attrs) => {
      const COLORS = {
        green: $attrs.positiveColor || '#00812e',
        yellow: $attrs.neutralColor || '#ffbc3c',
        red: $attrs.negativeColor || '#ed1c24'
      };

      $scope.state = {
        fontFamily: $scope.fontFamily || "Century Gothic, AppleGothic"
      };

      // TODO: HACK - By some reason phantomjs in Linux uses not Arial font, thought it is sent correctly in the fontFamily attr.
      if ($scope.cancelFontSetup && !$scope.state.fontFamily.match(/Arial/)) $scope.state.fontFamily = 'Arial,sans-serif';

      const maxMargin = 7;

      const initLayout = (force = false) => {
        let width = $attrs.width || ($scope.size && $scope.size.width) || 300;
        let height = $attrs.height || ($scope.size && $scope.size.height) || 300;

        if (force){
          $scope.rangeMax = (width / 6);
          $scope.rangeMin = (width / 30);
        }
        else{
          $scope.rangeMax = $scope.rangeMax || (width / 6);
          $scope.rangeMin = $scope.rangeMin || (width / 30);
        }

        let range = $scope.rangeMax - $scope.rangeMin;
        let reducingCoef = Math.floor(range / 20);

        return cloud().font($scope.state.fontFamily)
          .size([width, height])
          .padding(!!$scope.padding ? $scope.padding * 1 : 0)
          .rotate(0)
          .fontSize(d => d.size)
          .on('end', (output) => {
            // console.log('output', map('size', output));
            // To avoid outputting not all words
            if ($scope.words && $scope.words.length !== output.length && $scope.rangeMax > $scope.rangeMin) {
              $scope.rangeMax = Math.max($scope.rangeMax - reducingCoef, $scope.rangeMin);
              setRangesAndRun()
            } else {
              draw(output)
            }
          });
      };

      let layout = initLayout();

      const draw = (words) => {
        const svg = d3.select($element[0]);

        svg.select('svg').remove();
        const list = svg.append('svg')
            .attr("width", layout.size()[0])
            .attr("height", layout.size()[1])
          .append("g")
            .attr("transform", "translate(" + layout.size()[0] / 2 + "," + layout.size()[1] / 2 + ")")
          .selectAll("text")
            .data(words)
          .enter().append("text")
            .style("font-size", d => d.size + "px" )
            .style("font-family", $scope.state.fontFamily)
            .attr("text-anchor", "middle")
            .attr("transform", d => "translate(" + [d.x, d.y] + ")rotate(" + d.rotate + ")")
            .text(d => d.text);

        if ($scope.noFill !== 'true') {
          if ($scope.showProjectColor === 'true') {
            list.style("fill", d => $scope.color || COLORS[d.project_color] || d.project_color || "#000000");
          } else {
            list.style("fill", d => $scope.color || COLORS[d.color] || COLORS[d.status_name] || d.color || "#000000");
          }
        } else {
          list.style("fill", d => $attrs.emptyColor || "#000000");
        }
        if (!!$scope.onWordClick) list.on("click", d => $scope.onWordClick({text: d.text}));
      };

      const setRangesAndRun = () => {
        let words = reverse(sortBy('total')($scope.words));

        const scale = d3.scale.linear()
          .domain([d3.min(words, d => d.total) - 0.1, d3.max(words, d => d.total)])
          .range([$scope.rangeMin, $scope.rangeMax]);

        // Setup sizes with max margin between words.
        const eachWithIndex = each.convert({ 'cap': false });
        eachWithIndex((w, i) => {
          w.size = Math.floor(scale(w.total));
          let prevW = words[i-1];
          if (!!prevW){
            if (prevW.size - maxMargin > w.size) prevW.size = w.size + maxMargin
          }
        })(words);

        layout.words(words);
        layout.start();
      };

      $scope.$watch(() => { return $scope.words ? map((w) => w.text, $scope.words).join(',') : null }, (newWords, oldWords) => {
        if (!newWords) return;

        if ($scope.cancelFontSetup || (!!oldWords && !!newWords)){ setRangesAndRun(); }
        else {
          FontLoader([$scope.state.fontFamily], () => { setRangesAndRun(); });
        }
      });

      $scope.$watch('noFill', () => {
        layout.start();
      });

      $scope.$watch('showProjectColor', () => {
        layout.start();
      });

      $scope.$watch('size', (value) => {
        if(!value) return;

        layout = initLayout(true);
        setRangesAndRun();
      }, true);
    }
  }
}]);
