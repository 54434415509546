import app from '../common_app.es6';
import { each } from 'lodash/fp';

app.directive('regionsMapper', [ '$rootScope', ($rootScope) => {
  return {
    restrict: 'E',

    scope:{
      initRegions: '=',
      markupTool: '=',
      imageKlass: '@'
    },

    template: require('./regions_mapper_template.html'),

    link: ($scope, $element) => {

      const eachWithIndex = each.convert({ 'cap': false });

      $scope.visible = $scope.initRegions.length>0;
      $scope.readOnly = $scope.initRegions.length>0;
      $scope.showSelection = false;
      $scope.start = {};
      $scope.s = {};
      $scope.regions = $scope.initRegions||[];

      // console.log('$scope.initRegion',$scope.initRegion);

      $scope.$on('imageChanged', (event) => {
        $scope.visible = false;
      });

      $scope.$on('region-create', (event) => {
        $scope.visible = true;
        $scope.readOnly = false;
        $scope.regions = [];
      });

      $scope.$on('region-settings-reset', (event, data) => {
        var region = data.concept.regions.find((el)=>{return el.id == data.regionId });
        $scope.readOnly = true;
        $scope.visible = !!region && region.data.length > 0;
        $scope.regions = !!region ? region.data : [];
      });

      $scope.$on('region-settings-updated', (event, data) => {
        $scope.visible = data.regions.length > 0;
        $scope.readOnly = true;
        $scope.regions = data.regions;
      });

      $scope.$on('region-readonly', (event, data) => {
        var region = data.concept.regions.find((el)=>{return el.id == data.regionId });
        $scope.readOnly = true;
        $scope.visible = !!region && region.data.length>0;
        $scope.regions = !!region ? region.data : [];
      });

      $scope.$on('region-hide', (cancelled) => {
        $scope.visible = cancelled;
      });

      $scope.showCloseSquare = false;
      $scope.closeSquareX = null;
      $scope.closeSquareY = null;
      $scope.$on('show-close-square', (ev, frame) => {
        $scope.showCloseSquare = !!frame;
        if($scope.showCloseSquare){
          $scope.closeSquareX = frame.left+frame.width+4;
          $scope.closeSquareY = frame.top-3;
        }
      });

      $scope.deselect = (event)=>{
        event.stopPropagation();
        $rootScope.$broadcast('deselect-comments');
      };

      $scope.deleteRegion = (region)=> {
        $scope.regions = $scope.regions.filter((el)=>{ return el!=region });
        $rootScope.$broadcast('regions-areas-changed', $scope.regions);
      };

      $scope.startSelection = (event) => {

        if($scope.readOnly || event.target.classList.contains('close-region')){
          $rootScope.$broadcast('regions-click', event);
          return;
        }

        event.preventDefault();
        event.stopPropagation();

        $scope.showSelection = true;
        var cc = $scope.getCoords(event);
        $scope.start = {x: cc.x, y: cc.y};
        $scope.s = {};
        $scope.applySelectionCoords(cc.x, cc.y);
      };

      $scope.changeSelection = (event)=>{
        if($scope.showSelection){
          event.preventDefault();
          event.stopPropagation();

          var cc = $scope.getCoords(event);
          $scope.applySelectionCoords(cc.x, cc.y);
        }
      };

      $scope.finishSelection = (event) => {
        if($scope.showSelection) {

          event.preventDefault();
          event.stopPropagation();

          $scope.showSelection = false;
          var cc = $scope.getCoords(event);
          $scope.applySelectionCoords(cc.x, cc.y);

          if($scope.s.width > 3 && $scope.s.height > 3){
            $scope.regions.push($scope.s);
            $rootScope.$broadcast('regions-areas-changed', $scope.regions);
          }
        }
      };

      $scope.applySelectionCoords = (x, y) => {
        if($scope.start.x>x){
          $scope.s.left = x;
          $scope.s.width = $scope.start.x-x;
        }else{
          $scope.s.left = $scope.start.x;
          $scope.s.width = x-$scope.start.x;
        }

        if($scope.start.y>y){
          $scope.s.top = y;
          $scope.s.height = $scope.start.y - y;
        }else{
          $scope.s.top = $scope.start.y;
          $scope.s.height = y - $scope.start.y;
        }
        let regionsEl = !!$scope.imageKlass ? $($scope.imageKlass) : $('.regions');

        $scope.s.image_width = regionsEl.outerWidth();
        $scope.s.image_height = regionsEl.outerHeight();
      };

      $scope.getCoords = (evt) => {
        var $el = $element.find('.regions');

        var x = evt.pageX - $el.offset().left;
        var y = evt.pageY - $el.offset().top;
        return { x: x, y: y };
      };

      $scope.scaledRegions = () => {
        eachWithIndex((region) => {
          $scope.markupTool.mergeScaledRegion(region);
        })($scope.regions);

        return $scope.regions;
      };
    }

  }
}]);