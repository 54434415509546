import app from '../common_app.es6';

app.directive('compiledOutput', [ '$parse', '$compile', ($parse, $compile) => {
  return {
    link: ($scope, $element, $attrs) => {
      const removeWatcher = $scope.$watch(() => $attrs.compiledOutput, (newVal) => {
        const el = $compile(newVal)($scope);
        $element.html('');
        $element.append(el);
      });

      $scope.$on('$destroy', () => {
        removeWatcher && removeWatcher();
      });
    }
  };
} ]);
