import app from '../common_app.es6';

app.directive('scroller', () => {
  return {
    restrict: 'A',
    scope: {},
    link: ($scope, $element, $attrs) => {

      var itemSelector = '.survey-reactions-comments_item';

      $scope.$on('changeSelection', (event, id) => {
        if (!id) return;

        var firstElementTop = $($element.find(itemSelector)[0]).position().top;
        var itemTop = $element.find('#resp-' + id).position().top;
        var diff = itemTop - firstElementTop;
        $element.find('.js-scrollable').scrollTop(diff);
      });
    }
  }
});