import app from '../common_app.es6';

app.directive('imagePreview', [ 'imagePreviewModal', (imagePreviewModal) => {
  return {
    restrict: 'A',
    scope: { imageUrl: '@imagePreview', previewContainer: '@'},
    link: ($scope, $element) => {

      if ($scope.imageUrl){

        var tooltipShown = false, touched = false;

        let hash = {
          placement: function (tip, element) {
            var top = $(element).offset().top - $(window).scrollTop(),
              left = $(element).offset().left - $(window).scrollLeft(),
              bottom = $(window).height() - $(element).height() - top,
              right = $(window).width() - $(element).width() - left;

            if (top < 270) {
              if (bottom < 270) {
                if (right < 270) {
                  if (left >= 270) return 'left';
                } else return 'right';
              } else return 'bottom';
            }

            return 'top';
          },
          title: $('<img/>', {
            src: $scope.imageUrl,
            alt: '',
            class: 'selectable-list-item_tooltip-image'
          }),
          html: true,
          trigger: 'manual',
          template:
          '<div class="tooltip -contains-image" role="tooltip">' +
          '<div class="tooltip-arrow"></div>' +
          '<div class="tooltip-inner"></div>' +
          '</div>'
        };

        if ($scope.previewContainer){ hash.container = $scope.previewContainer }

        $element.tooltip(hash).on('touchstart', function() {
          touched = true;
        }).on('mouseenter', function () {
          if (!touched && window.matchMedia('(min-width: 480px)').matches) {
            $(this).tooltip('show');
          }
        }).on('mouseleave', function () {
          if (!touched && window.matchMedia('(min-width: 480px)').matches) {
            $(this).tooltip('hide');
          }
        }).on('click', function (event) {
          if (window.matchMedia('(max-width: 479px)').matches) {
            if (!imagePreviewModal.opened()) imagePreviewModal.show($scope.imageUrl);
          } else {
            if (touched && !tooltipShown) {
              $('[image-preview]').not(this).tooltip('hide');
              $(this).tooltip('show');
            } else if (touched && tooltipShown) {
              $(this).tooltip('hide');
            }
          }
        }).on('shown.bs.tooltip', function() {
          tooltipShown = true;
        }).on('hidden.bs.tooltip', function () {
          tooltipShown = false;
        });

        $('html').on('click', '[data-dismiss="modal"]', function (e) {
            imagePreviewModal.hide();
        });

      }
    }
  }
}]);


app.factory('imagePreviewModal', ['$uibModal', function($uibModal) {
    var local;

    return {
      opened: () => { return !!local },
      show: (url) => {
        local = $uibModal.open({
          windowClass: '-contains-image-only js-selectable-list-image-modal',
          template: '<button class="close fa fa-window-close" data-dismiss="modal" type="button"></button><div class="modal-body"><img src="'+url+'"></div>'
        });
        local.result.then(function(){}, function(res){});
      },
      hide: () => {
        if (local) {
          local.close();
          local = null;
        }
      }
    };
}]);