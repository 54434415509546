import app from '../common_app.es6';
import { groupBy, each } from 'lodash/fp';
import { merge, filter, find, map, uniq } from "lodash";

app.factory('MarkupTool', [() => {

  const eachWithIndex = each.convert({ 'cap': false });

  return class MarkupTool {

    constructor(allComments, reactions, regions, selectedRegionId, width, markupType) {
      this.allComments = allComments;

      this.selectedComments = [];
      this.groupedSelectedComments = {};
      this.groupedComments = {};

      this.markupComments = [];
      this.offsetX = null;
      this.offsetY = null;

      this.reactions = reactions;
      this.regions = this._convertRegions(regions);
      this._changeSelectedRegionId(selectedRegionId);
      this.width = width;
      this.markupType = markupType;

      // this._applyFilters();
    }

    setAllComments(allComments, selectedRegionId, reactions, markupType, regions){
      this.anyHighlighted = false;
      if(!!reactions){ this.reactions = reactions; }
      if(!!markupType){ this.markupType = markupType; }
      if(!!regions){ this.regions = this._convertRegions(regions); }
      this._changeSelectedRegionId(selectedRegionId);
      this.allComments = allComments;
      this._applyFilters();
    }

    setReactions(reactions){
      this.reactions = reactions;
      this._applyFilters();
    }

    setMarkupType(markupType){
      this.markupType = markupType;
      this._applyFilters();
    }

    setRegions(regions, selectedRegionId){
      this.regions = this._convertRegions(regions);
      this._changeSelectedRegionId(selectedRegionId);
      this._applyFilters();
    }

    setSelectedRegion(selectedRegionId){
      this._changeSelectedRegionId(selectedRegionId);
      this._applyFilters();
    }

    setClipMode(clipMode){
      this.clipMode = clipMode;
      this._applyFilters();
    }

    setWidth(width){
      this.width = width;
      this._applyFilters();
    }

    getMarkupComments(){
      return filter(this.markupComments, (el) => { return !el.respondent_hidden && el.markup_visible });
    }

    getSelectedRegion(){
      return this.regions[this.selectedRegionId]
    }

    areaClick(offsetX, offsetY, useCurrent){
      if (!useCurrent){
        this.offsetX = offsetX;
        this.offsetY = offsetY;
      }
      this._setSC();
    }

    hasSelectedComments(){
      return this.selectedComments.length>0;
    }

    deselectComments(){
      this.selectedComments = [];
      this.groupedSelectedComments = {};
      eachWithIndex((comment, indexI) => {
        eachWithIndex((area, indexJ) => {
          area.isSelected=false;
        })(comment.areas);
      })(this.allComments);

      this.offsetX = null;
      this.offsetY = null;

      this._setGC();
    }

    checkAllRespondentsHidden(){
      let allHidden = true;
      eachWithIndex((el) => {
        if (!el.respondent_hidden) allHidden = false;
      })(this.allComments);
      return allHidden;
    };

    toggleAllRespondents(visible){
      eachWithIndex((el) => {
        el.respondent_hidden = !visible;
      })(this.allComments);
      this.areaClick(null, null, true);
      return uniq(map(this.allComments, (el) => el.respondent_id));
    };

    toggleRespondent(respondent_id){
      let respondent = null;

      eachWithIndex((el) => {
        if (el.respondent_id == respondent_id) {
          respondent = el;
          el.respondent_hidden = !el.respondent_hidden;
          if(el.respondent_hidden){
            el.areas.each((area)=>{
              area.isSelected = false;
            });
          }
        }
      })(this.allComments);
      this.areaClick(null, null, true);
      if (!respondent) return {};
      return respondent;
    };

    toggleMarkupVisible(report_comment_id){
      let report = find(this.allComments, (el) => el.id == report_comment_id);

      if (!report) return {};

      report.markup_visible = !report.markup_visible;
      if(!report.markup_visible){
        report.areas.each((area) => { area.isSelected = false; });
      }
      this.areaClick(null, null, true);
      return report;
    };

    toggleHighlight(respondent, isSelected){

      let _this = this;

      _this.anyHighlighted = false;

      eachWithIndex((comment, indexI) => {
        eachWithIndex((area, indexJ) => {
          if (respondent && comment.id == respondent.id){
            area.isHighlighted = !area.isHighlighted;
          }
          else {
            area.isHighlighted = false;
          }
          if (area.isHighlighted) _this.anyHighlighted = true;

        })(comment.areas);
      })(this.allComments);
    }

    moveHighlight(moveUp){
      let selKeys = Object.keys(this.groupedSelectedComments),
          selPos = this._findHighlighted(this.groupedSelectedComments, selKeys);
      if(selPos != null){
        this._moveInsideGrouped(moveUp, selPos[0], selPos[1], this.groupedSelectedComments, selKeys);
      }else{
        let othKeys = Object.keys(this.groupedComments),
            othPos = this._findHighlighted(this.groupedComments, othKeys);
        if(othPos != null){
          this._moveInsideGrouped(moveUp, othPos[0], othPos[1], this.groupedComments, othKeys);
        }
      }
    }

    _convertRegions(regions){
      if(Array.isArray(regions)){
        let result = {};
        regions.each((el)=>{ result[el.id] = el });
        return result;
      }else{
        return regions;
      }
    }

    _moveInsideGrouped(moveUp, ki, ci, grouped, keys){
      let comments = grouped[keys[ki]],
          areas = comments[ci].filterAreas;

      if( moveUp && (ki>0 || ci>0) ){
        this._setIsHighlighted(areas, false);

        let newKi = (ci == 0 ? ki-1 : ki),
            newCi = (ci == 0 ? grouped[ keys[ki-1] ].length-1 : ci-1),
            newAreas = grouped[ keys[newKi] ][newCi].filterAreas;

        this._setIsHighlighted(newAreas, true);
      }

      if( !moveUp && (ki<keys.length-1 || ci<comments.length-1) ){
        this._setIsHighlighted(areas, false);

        let newKi = (ci == comments.length-1 ? ki+1 : ki),
            newCi = (ci == comments.length-1 ? 0 : ci+1),
            newAreas = grouped[ keys[newKi] ][newCi].filterAreas;

        this._setIsHighlighted(newAreas, true);
      }

    }

    _findHighlighted(grouped, keys){
      for(let ki=0; ki<keys.length; ki++) {
        let comments = grouped[keys[ki]];
        for (let ci = 0; ci < comments.length; ci++) {
          let areas = comments[ci].filterAreas,
            hasHighlight = false;
          for (let ai = 0; ai < areas.length; ai++) {
            if (areas[ai].isHighlighted) {
              hasHighlight = true
            }
          }
          if(hasHighlight){
            return [ki,ci]
          }
        }
      }
      return null
    }

    _setIsHighlighted(areas, v){
      for(let ai=0; ai<areas.length; ai++){ areas[ai].isHighlighted = v; }
    }

    anyCommentVisible(){
      if(!this.markupComments){
        return false;
      }
      return this.markupComments.any((el)=>{ return el.filterAreas.length>0; })
    }

    isRespondentHidden(respondent_id){
      var gc = this.groupedComments,
          gsc = this.groupedSelectedComments,
          temp = gc[respondent_id]  && gc[respondent_id].any((el)=>{ return !!el.respondent_hidden }) ||
                 gsc[respondent_id] && gsc[respondent_id].any((el)=>{ return !!el.respondent_hidden });

      return !!temp;
    }

    hintMessage(respondent_id){
      if(this.isRespondentHidden(respondent_id)){
        return `Show all respondent's markups (${respondent_id}) on markup and heatmap view`;
      }else{
        return `Hide all respondent's markups (${respondent_id}) from markup and heatmap view`
      }
    };

    hintMarkupKind(image){
      return image ? 'Image Highlighter' : 'Text Highlighter';
    }

    respondentClasses(respondent, isSelected){
      return {
        '-positive': respondent.reaction == 'positive',
        '-negative': respondent.reaction == 'negative',
        '-confusing': respondent.reaction == 'confusing',
        '-selected': respondent.filterAreas.any( (el)=>{ return el.isHighlighted} ),
        '-commented': !!respondent.comment,
        '-with-reaction': respondent.areas.length>0
      }
    }

    mergeScaledRegion(region){
      if (!region.image_width) region.image_width = 452;

      if (!!this.width && !region.scaled && region.image_width != this.width){
        region.scaled = true;
        merge(region, this._getScaledArea(this.width, region, region.image_width));
      }

      return region;
    }

    //private

    _allAreasSelected(el){
      return el.filterAreas.all((area)=>{ return !!area.isSelected; });
    }

    _setGSC(){
      this.groupedSelectedComments= this.selectedComments.groupBy('respondent_id');
    }

    _setGC(){
      this._setGSC();
      this.groupedComments = filter(this.markupComments, (el) => { return !this._allAreasSelected(el); }).groupBy('respondent_id');
    }

    _setSC(){
      this.selectedComments = [];

      if(this.offsetX == null || this.offsetY == null){
        this._setGC();
        return;
      }

      eachWithIndex((comment, indexI) => {
        var selected = false,
            resultId = null;

        if (!comment.respondent_hidden && comment.markup_visible ) {

          eachWithIndex((area, indexJ) => {
            var scaledArea = this._getScaledArea(this.width, area, comment.image_width);

            if (!!scaledArea && this._isInside(this.offsetX, this.offsetY, scaledArea)) {
              selected = true;
              area.isSelected = true;
              if (resultId == null) {
                resultId = comment.id
              }
            }
            else {
              area.isSelected = false;
            }
          })(comment.filterAreas);

          if (selected) {
            this.selectedComments.push(comment);
          }
        }
      })(this.markupComments);
      this._setGC();
    }

    _changeSelectedRegionId(selectedRegionId){
      this.selectedRegionId = selectedRegionId;
      this.clipMode = (this.regions && this.regions[this.selectedRegionId] && this.regions[this.selectedRegionId].clip_mode) || 'none';
      this.clipRegions = (this.regions && this.regions[this.selectedRegionId] && this.regions[this.selectedRegionId].data) || [];
    }


    _isInside(x, y, obj){
        return !(y <= obj.top || (obj.top + obj.height <= y) || x <= obj.left || (obj.left + obj.width) <= x)
    }


    _applyFilters(){
      this.markupComments = filter(this.allComments, (el) => {
        if(
          (
            el.reaction == 'positive' && this.reactions.positive ||
            el.reaction == 'negative' && this.reactions.negative ||
            el.reaction == 'confusing' && this.reactions.confusing
          )
          &&
          (this.markupType == 'area_and_text' || el.markup_type == this.markupType)
        ){
          return true;
        }
        return false;
      });

      eachWithIndex((el, indexI) => {
        el.filterAreas = this._filterAreas(el);
      })(this.markupComments);

      this._setSC();

    }

    _filterAreas(el){
      if(this.clipMode == 'none' || this.width == null){
        return el.areas;
      }
      return filter( el.areas, (area) => {
        var i = this._intersectWithRegions(area, el.image_width);
        return this.clipMode == 'inside' && i == 1 || this.clipMode == 'outside' && i == -1 || this.clipMode == 'covered' && (i == 0 || i == 1);
      });
    }

    _intersectWithRegions(area, image_width){
      if(!this.clipRegions || this.clipRegions.length==0){return -1}
      var scaledArea = this._getScaledArea(this.width, area, image_width),
          ax1 = scaledArea.left,
          ay1 = scaledArea.top,
          ax2 = scaledArea.left+scaledArea.width,
          ay2 = scaledArea.top+scaledArea.height;
      for(var i=0; i<this.clipRegions.length; i++){
        let region = this.clipRegions[i];

        this.mergeScaledRegion(region);

        let bx1 = region.left,
            by1 = region.top,
            bx2 = region.left + region.width,
            by2 = region.top + region.height;

        if( bx1 <= ax1 && ax2 <= bx2 && by1 <= ay1 && ay2 <= by2){
          return 1;
        }
        if( ax1 < bx2 && ax2 > bx1 && ay1 < by2 && ay2 > by1 ){
          return 0;
        }
      }
      return -1;
    }

    _getScaledArea(baseWidth, area, image_width){
      var scale = baseWidth / parseFloat(image_width);
      var scaledArea = {
        left: parseFloat(area.left) * scale,
        top: parseFloat(area.top) * scale,
        width: parseFloat(area.width) * scale,
        height: parseFloat(area.height) * scale
      };
      scaledArea.right = scaledArea.left + scaledArea.width;
      scaledArea.bottom = scaledArea.top + scaledArea.height;
      return scaledArea;
    }


  };

}]);