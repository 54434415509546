import app from '../common_app.es6';

app.directive('markups', [ '$timeout', '$rootScope', ($timeout, $rootScope) => {
  return {
    restrict: 'E',
    template:
      '<div class="survey-reactions-interface_image-canvas-wrapper">'+
        '<img class="survey-reactions-interface_image" ng-src="{{ imageSrc | urlSafe }}" image-loaded="onImageLoad($event, $image)" />' +
        '<canvas class="survey-reactions-interface_canvas" mouse-down="areaClick($event)" />'+
        '<div class="widget-heatmap_label" ng-if="label.show">{{label.text}}</div>'+
        '<div class="widget-heatmap_value" ng-if="label.show" ng-class="label.class">{{label.value}}</div>'+
      '</div>',
    replace: true,
    scope: {
      imageSrc: '@',
      activeTab: '@',
      comments: '&',
      label: '=',
      threshold: '='
    },
    link: ($scope, $element) => {

      var getTreshold = (treshold) => { return (parseInt(treshold, 10) || 50) / 100 };

      var getMinTreshold = (treshold) => { return (1 - getTreshold(treshold)) };
      var getMaxTreshold = (maxValue, treshold) => { return maxValue * ( 2 - getTreshold(treshold)) };

      var greenColor = '#4ec09a';
      var redColor = '#ff7970';
      var orangeColor = '#d0b050';
      var grayColor = '#d0d0d0';
      var borderColor = '#000000';

      var width = 0;
      var height = 0;
      var heatmapScale=1;

      var heatmap,
          heatmapResolution = 100,
          heatmapRenderWidth = 800,
          heatmapAreaSize = heatmapRenderWidth/heatmapResolution;

      var clipMode = 'none', clipRegions = [];

      $scope.list = () => {
        var result = $scope.comments().call();

        if(clipMode != 'none'){
          result = result.filter((el)=>{
            if (el.area.left) {
              var intersection = $scope.intersectWithRegions(el);
              return clipMode == 'inside' && intersection == 1 || clipMode == 'outside' && intersection == -1 || clipMode == 'covered' && (intersection == 0 || intersection == 1);
            }else{
              return false;
            }
          });
        }

        return result;
      };

      $scope.onImageLoad = function(ev, image) {
        // TODO: quickfix
        var c = setInterval(() => {
          var $img = $element.find('.survey-reactions-interface_image');

          width = $img.outerWidth();
          height = $img.outerHeight();
          heatmapScale = $img.outerWidth()/heatmapRenderWidth;

          if (width <= 800){
            clearInterval(c);
            var $canvas = $element.find('canvas.survey-reactions-interface_canvas');
            $canvas.attr('width', width).attr('height', height);

            render();
            renderHeatmap();
          }
        }, 100);
      };

      var getScaledArea = (baseWidth, area) => {
        var scale = baseWidth / parseFloat(area.image_width);
        var scaledArea = {
          left: parseFloat(area.left) * scale,
          top: parseFloat(area.top) * scale,
          width: parseFloat(area.width) * scale,
          height: parseFloat(area.height) * scale
        };
        scaledArea.right = scaledArea.left + scaledArea.width;
        scaledArea.bottom = scaledArea.top + scaledArea.height;
        return scaledArea;
      };

      var drawMarkup = (ctx, comment, alpha, border) => {
        var scaledArea = getScaledArea(width, comment.area);
        ctx.globalAlpha = alpha;
        if(comment.liked != undefined){
          ctx.fillStyle = comment.liked ? greenColor : redColor;
        }else if(comment.reaction != undefined){
          if(comment.reaction=='positive') ctx.fillStyle = greenColor;
          if(comment.reaction=='negative') ctx.fillStyle = redColor;
          if(comment.reaction=='confusing') ctx.fillStyle = orangeColor;
        }else{
          ctx.fillStyle = grayColor;
        }

        ctx.fillRect(scaledArea.left, scaledArea.top, scaledArea.width, scaledArea.height);
        if (border){
          ctx.strokeStyle = borderColor;
          ctx.strokeRect(scaledArea.left, scaledArea.top, scaledArea.width, scaledArea.height);
        }
        ctx.globalAlpha = 1;
      };

      var render = () => {
        var canvas = $element.find('canvas')[0],
            context = canvas.getContext('2d'),
            list = $scope.list();
        context.clearRect(0, 0, canvas.width, canvas.height);
        var selectedComment = list.find((el) => { return el.selected });
        for(var i = 0; i < list.length; i++){
          var comment = list[i];
          if (comment != selectedComment && comment.area.left) drawMarkup(context, list[i], 0.2, false);
        }

        if (!selectedComment || !selectedComment.area.left) return;
        drawMarkup(context, selectedComment, 0.5, true);
      };

      var scoreCell = (pX, pY, cellWidth, cellHeight, list) => {
        var left = pX;
        var top = pY;
        var right = pX + cellWidth;
        var bottom = pY + cellHeight;
        var elements = list.filter(function(comment) {
          if (comment.area.left) {
            var scaledArea = getScaledArea(heatmapRenderWidth, comment.area);
            return !( scaledArea.bottom <= top || bottom <= scaledArea.top || right <= scaledArea.left || scaledArea.right <= left )
          }
        });
        return elements.sum((e) => { return (e.area && e.area.counter) || e.counter || 1});
      };

      var destroyCanvas = () => {
        if (!heatmap) return;
        var canvas = heatmap._renderer.canvas;
        $(canvas).remove();
        heatmap = null;
      };

      var renderHeatmap = (recreate) => {
        if (typeof recreate == 'undefined') recreate = true;

        if (recreate){
          destroyCanvas();
          heatmap = h337.create({
            container: $element[0],
            radius:  2.0*heatmapAreaSize,
            maxOpacity: 0.5,
            minOpacity: 0,
            blur: 0.75,
            scale: heatmapScale
          });
        }

        changeCanvasOpacity($scope.activeTab);

        var cells = [],
            pX, pY,
            maxCount = 0,
            list = $scope.list();

        for(var i =0; i < heatmapResolution; i++) {
          for(var j = 0; j < heatmapResolution; j++) {
            pX = j * heatmapAreaSize;
            pY = i * heatmapAreaSize;
            var score = scoreCell(pX, pY, heatmapAreaSize, heatmapAreaSize, list);
            if (score) {
              if (score > maxCount) maxCount = score;
              cells.push({
                value: score,
                x: pX+heatmapAreaSize/2,
                y: pY+heatmapAreaSize/2,
              })
            }
          }
        }
        if(heatmap){
          heatmap.setData({ max: getMaxTreshold(maxCount, $scope.treshold), min: getMinTreshold($scope.treshold), data: cells });
        }
      };

      $scope.$on('collectionChanged', () => {
        render();
        renderHeatmap(false);
      });

      $scope.$on('imageChanged', () => {
        render();
        renderHeatmap();
      });
      $scope.$on('respondentSelected', () => { render() });
      $scope.$on('tresholdChange', (event, newVal) => {
        if (newVal && heatmap){
          heatmap.setDataMin(getMinTreshold(newVal));
          var data = heatmap.getData();
          var maxEl = data.data.max('value');
          if (maxEl) heatmap.setDataMax(getMaxTreshold(maxEl.value, newVal));
        }
      });

      $scope.$on('resolutionChange', (event, newVal) => {
        heatmapResolution = newVal;
        renderHeatmap(false);
      });

      $scope.$on('new-clip-mode', (event, data) => {
        clipMode = data.mode;
        clipRegions = data.regions;
        render();
        renderHeatmap(false);
      });

      var changeCanvasOpacity = (newTab) => {
        $element.find('canvas.survey-reactions-interface_canvas').css('opacity', '0');
        $element.find('canvas.heatmap-canvas').css('opacity', '0');
        if (parseInt(newTab, 10) == 1) { $element.find('canvas.heatmap-canvas').css('opacity', '1') }
        else { $element.find('canvas.survey-reactions-interface_canvas').css('opacity', '1') }
      };

      $scope.$on('activeTabChange', (event, newTab) => { changeCanvasOpacity(newTab)});

      var prepareOffsets = (ev) => {
        if (ev.offsetX || !ev.touches) return;
        ev.offsetX = ev.touches[0].pageX - $(ev.touches[0].target).offset().left;
        ev.offsetY = ev.touches[0].pageY - $(ev.touches[0].target).offset().top;
      };

      var isInside = (x, y, obj) => {
        return !(y <= obj.top || (obj.top + obj.height <= y) || x <= obj.left || (obj.left + obj.width) <= x)
      };

      $scope.areaClick = (ev) => {
        prepareOffsets(ev);

        var selected = false;

        $scope.list().each((el) => {
          var area = el.area.left && getScaledArea(width, el.area);
          el.selected = !selected && !!area  && isInside(ev.offsetX, ev.offsetY, area);
          if (!selected && el.selected) selected = true
        });

        render();

        var el = $scope.list().find((el) => { return el.selected });

        $rootScope.$broadcast('changeSelection', el && el.id);
      };

      $scope.intersectWithRegions = (el) => {
        if(!clipRegions || clipRegions.length==0){return -1}

        var scaledArea = getScaledArea(el.area),
            covered = 0;

        for(var i=0; i<clipRegions.length; i++){

          if(
            scaledArea.left >= clipRegions[i].left &&
            scaledArea.width <= clipRegions[i].width &&
            scaledArea.top >= clipRegions[i].top &&
            scaledArea.height <= clipRegions[i].height
          ){
            return 1;
          }

          if(
            isInside(clipRegions[i].left,clipRegions[i].top,scaledArea) ||
            isInside(clipRegions[i].left+clipRegions[i].width,clipRegions[i].top,scaledArea) ||
            isInside(clipRegions[i].left,clipRegions[i].top+clipRegions[i].height,scaledArea) ||
            isInside(clipRegions[i].left+clipRegions[i].width,clipRegions[i].top+clipRegions[i].height,scaledArea)
          ){
            covered++;
          }

        }

        return covered>0 ? 0 : -1;
      };

    }
  }
}]);