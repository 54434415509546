import app from '../common_app.es6';

app.directive('imageLoaded', [ '$timeout', '$parse', ($timeout, $parse) => {
  return {
    restrict: 'A',
    link: function ($scope, $elem, $attrs) {
      var fn = $parse($attrs.imageLoaded);
      $elem.on('load', function (event) {
        var image = this;
        $timeout(() => { fn($scope, { $event: event, $image: image }); });
      });
    }
  }
}]);