import app from '../common_app.es6';

app.directive('mouseDown', [ '$timeout', '$parse', ($timeout, $parse) => {
  return {
    restrict: 'A',
    link: function ($scope, $elem, $attrs) {
      var fn = $parse($attrs.mouseDown);
      var onMouseDown = function(event) { $timeout(() => { fn($scope, { $event: event }); }); };
      $elem[0].addEventListener('touchstart', onMouseDown);
      $elem[0].addEventListener('mousedown', onMouseDown);
    }
  }
}]);