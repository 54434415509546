import app from '../common_app.es6';
import rangy from 'rangy';

app.directive('customColorPicker', ['$compile', '$timeout', '$rootScope', ($compile, $timeout, $rootScope) => {
  return {
    require: '^ngModel',
    scope: { onApply: '&' },
    link: ($scope, $element, $attrs, $modelCtrl) => {

      const defaultColor = '#000000';

      let initialSelRanges = [];

      $scope.state = {
        title: $attrs.title,
        opened: false,
        color: defaultColor
      };

      const installInitRanges = () => {
        const selection = rangy.getSelection();
        selection.removeAllRanges();
        for(let i in initialSelRanges) { selection.addRange(initialSelRanges[i]); }
      };

      const elementInNodesArray = (event, el) => {
        let curEl = event.target, bodyEl = $('body')[0], found = false;

        while (curEl != bodyEl ) {
          if (!found) found = (curEl == el);
          curEl = curEl.parentNode;
        }

        return found;
      };

      // val here = rgb(31, 37, 66)
      $scope.rgbToHex = (val) => {
        if (!val) return defaultColor;

        let matches = val.match(/rgb\(\s*(\d)+\s*,\s*(\d+)\s*,\s*(\d+)\s*\)/);

        if(!matches) return defaultColor;

        const rgbToHex = (color) => {
          let hex = Number(color).toString(16);
          if (hex.length < 2) { hex = "0" + hex; }
          return hex;
        };

        let red = rgbToHex(matches[1]);
        let green = rgbToHex(matches[2]);
        let blue = rgbToHex(matches[3]);
        return '#' + red + green + blue;
      };

      $scope.hexToRgb = (hex) => {
        var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
        const r = result ? parseInt(result[1], 16) : 0,
              g = result ? parseInt(result[2], 16) : 0,
              b = result ? parseInt(result[3], 16) : 0;
        return `rgb(${r},${g},${b})`;
      };

      $scope.calcStyles = () => {
        let scrollX = document.documentElement.scrollLeft || 0,
            scrollY = document.documentElement.scrollTop || 0,
            rect = $element[0].getBoundingClientRect();

        return {
          top: rect.top + rect.height + scrollX,
          left: rect.left + scrollY
        }
      };

      $scope.colorBoxStyles = () => {
        return { backgroundColor: $scope.state.color };
      };

      let template = require('./custom_color_picker_template.html');
      template = $compile(template)($scope);
      $('body').append(template);

      template.find('input[type="text"]').blur(() => { installInitRanges(); });
      template.click((event) => {
        if (
          !(
            elementInNodesArray(event.originalEvent, template.find('input[type="text"]')[0]) ||
            elementInNodesArray(event.originalEvent, template.find('button')[0])
          )
        ){
          installInitRanges();
        }
      });

      $scope.onElementClick = (event) => {
        event.preventDefault();
        $rootScope.$broadcast("customColorPickerOpenedOther", $scope.state.title);

        initialSelRanges = [];
        const selection = rangy.getSelection();

        for(let i = 0; i < selection.rangeCount; i++) {
          initialSelRanges.push(selection.getRangeAt(i));
        }
        $timeout(() => { $scope.state.opened = true;});
      };

      $scope.cancel = () => { $scope.state.opened = false; };

      $scope.apply = () => {
        installInitRanges();
        $modelCtrl.$setViewValue($scope.hexToRgb($scope.state.color));
        $scope.onApply && $scope.onApply() && $scope.onApply()();
        $scope.cancel();
      };

      $scope.onClickOutside = (event) => {
        if (
          !(
            elementInNodesArray(event, $element[0]) ||
            elementInNodesArray(event, template[0])
          )
        ) {
          $timeout(() => { $scope.state.opened = false; });
        }
      };

      $scope.onScroll = (event) => { $timeout(() => {}); };

      $element[0].addEventListener('mousedown', $scope.onElementClick);
      $element[0].addEventListener('touchstart', $scope.onElementClick);
      $('body')[0].addEventListener('mousedown', $scope.onClickOutside);
      $('body')[0].addEventListener('touchstart', $scope.onClickOutside);
      $('.content')[0].addEventListener('scroll', $scope.onScroll);

      $scope.$on("customColorPickerOpenedOther", (event, title) => {
        if (title != $scope.state.title) $scope.state.opened = false;
      });

      $scope.$on("$destroy", () => {
        $element[0].removeEventListener('mousedown', $scope.onElementClick);
        $element[0].removeEventListener('touchstart', $scope.onElementClick);

        $('body')[0].removeEventListener('mousedown', $scope.onClickOutside);
        $('body')[0].removeEventListener('touchstart', $scope.onClickOutside);

        $('.content')[0].removeEventListener('scroll', $scope.onScroll);
        template.remove();
      });
    }
  };
}]);