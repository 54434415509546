import angular from 'angular';
import app from '../common_app.es6';

const FILTER = { ASC: 'asc', DESC: 'desc' };
const TABLE_CLASS = 'table -sortable -text-right order-of-products_table order-of-products-table';
const FILTER_CLASS = { ASC: '-asc', DESC: '-desc' };
const TABLE_TEMPLATE =
  `<table class="${TABLE_CLASS}">
    <colgroup>
      <col ng-repeat="column in config.header" ng-style="{ width: column.width }">
    </colgroup>
    <thead><tr>
      <th ng-repeat="column in config.header">
        <span ng-if="!column.sortable">{{ column.name }}</span>
        <span ng-if="column.sortable" class="table-sorter" ng-class="columnClass(column)" ng-click="filter(column)">
          {{ column.name }}
          <span class="table-sorter_controls"></span>
        </span>
      </th>
    </tr></thead>
    <placeholder></placeholder>
  </table>`;

app.directive('sortableTable', [() => {
  return {
    restrict: 'A',
    scope: {
      items: '=sortableTable',
      config: '=config'
    },
    transclude: true,
    template: TABLE_TEMPLATE,
    link: ($scope, $element, $attrs, ctrls, transclude) => {
      angular.element($element).addClass(TABLE_CLASS);
      transclude($scope, (clonedContent) => $element.find('placeholder').replaceWith(clonedContent));
    },
    controller: [ '$scope', '$filter', ($scope, $filter) => {
      const state = { filter: {} };
      const getCurrentFilter = () => Object.keys(state.filter)[0];

      $scope.$data = [];
      $scope.$ctrl = $scope.$parent;

      $scope.initFilter = (data) => {
        let column = data.find((i) => i.direction != null);
        if (column) {
          const field = getCurrentFilter() || column.field; // when we set the key, it'll convert toString() automatically

          if (state.filter[field] != null) {
            state.filter[field] = !state.filter[field];
            column = { field };
          } else if (column.direction === FILTER.ASC) {
            state.filter[column.field] = true;
          }

          $scope.filter(column);
        }
      };

      $scope.init = (items) => {
        $scope.$data = angular.copy(items);
        $scope.initFilter($scope.config.header);
      };

      $scope.columnClass = (column) => {
        const filter = state.filter[column.field];
        return filter == null ? '' : (!filter ? FILTER_CLASS.ASC : FILTER_CLASS.DESC);
      };

      $scope.filter = (column) => {
        const value = !state.filter[column.field];
        const fields = typeof column.field === 'string' ? column.field.split(',') : column.field;
        state.filter = { [column.field]: value };
        $scope.$data = $filter('orderBy')($scope.$data, fields, value);
      };

      $scope.$watch('items', (newValue) => $scope.init(newValue));
    }]
  }
}]);
