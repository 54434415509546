import { map, each, filter, findIndex } from 'lodash';
import app from '../common_app.es6';

app.controller('ImportModalController', [ "$scope", "$uibModalInstance", "$uibModal", "data", '$http', '$timeout', '$rootScope',
  ($scope, $uibModalInstance, $uibModal, data, $http, $timeout, $rootScope) => {
    $scope.isOpened = true;
    $scope.config = {
      processResult: false
    };
    $scope.clients = [];

    $scope.opened = function () {
      $scope.loadConcepts(data.urlProjects);
      $scope.clients = data.clients;
      $scope.defaultLoopField = data.defaultLoopField;
    };

    $uibModalInstance.opened.then($scope.opened);
    $uibModalInstance.closed.then(() => { $scope.isOpened = false; });

    $scope.save = function () {
      $uibModalInstance.close();
    };

    $scope.cancel = function () {
      $uibModalInstance.dismiss();
    };

    $scope.loadConcepts = (startUrl) => {
      $scope.loadConceptsStatus = 'loading';
      $http.get(
        startUrl
      ).then(
        (response) => {
          if (response.data.jid) {
            $scope.loadConceptsStatus = 'requesting';
            $scope.jid = response.data.jid;
            $scope.checkUrl = response.data.check_url;
            $timeout($scope.checkStatus, 1000);
          } else {
            $scope.loadConceptsStatus = 'failed';
          }
        }
      );
    };

    $scope.checkStatus = () => {
      if (!$scope.isOpened) { return; }

      $http.get(
        $scope.checkUrl
      ).then(
        (response) => {
          if (response.data.status == 'failed') {
            $scope.loadConceptsStatus = 'failed';
          } else if (response.data.status == 'complete') {
            $scope.loadConceptsStatus = 'select';
            $scope.selectProjects = response.data.projects;
          } else {
            $timeout($scope.checkStatus, 3000);
          }
        }
      );
    };

    $scope.selectedProjects = [];

    $scope.toggleSelection = function toggleSelection(id) {
      const idx = $scope.selectedProjects.indexOf(id);
      if (idx > -1) {
        $scope.selectedProjects.splice(idx, 1);
      } else {
        $scope.selectedProjects.push(id);
      }
    };

    $scope.importCountText = (defaultText) => {
      const cnt = $scope.selectedProjects.length;
      if (cnt > 0) {
        return (`${cnt + (cnt == 1 ? " project" : " projects")} selected to import`);
      }
      return defaultText;
    };

    $scope.isSemi = () => {
      return $scope.selectedProjects.length > 0 && $scope.selectedProjects.length < $scope.selectProjects.length;
    };

    $scope.checkAll = () => {
      if ($scope.selectedProjects.length < $scope.selectProjects.length) {
        $scope.selectedProjects = map($scope.selectProjects, (el) => { return el.api_id; });
      } else {
        $scope.selectedProjects = [];
      }
    };

    $scope.next = () => {
      $scope.loadConceptsStatus = 'details';
      each($scope.filteredProjects(), (p) => { if (!p.loop_field) {p.loop_field = $scope.defaultLoopField;} });
    };

    $scope.back = () => {
      $scope.loadConceptsStatus = 'select';
    };

    $scope.filteredProjects = () => {
      return filter($scope.selectProjects, (project) => {
        return findIndex($scope.selectedProjects, (apiId) => { return project.api_id == apiId; }) > -1;
      });
    };


    $scope.startImport = () => {
      const info = map($scope.filteredProjects(), (p) => {
        return {
          api_id: p.api_id,
          settings: p.settings,
          loop: p.loop_questions,
          loop_field: p.loop_field,
          client_id: p.clientId
        };
      });

      $http.post(
        data.urlStart,
        {
          info,
          process_result: $scope.config.processResult
        }
      ).then((response) => {
        $scope.save();
        $rootScope.$broadcast('start-indication');
      });
    };
  } ]);
