import { Promise } from 'es6-promise';
import fileType from './file_type.es6';

const defaultFormats = [
  'jpg',
  'jpeg',
  'png',
  'gif'
];

let MimeType = {

  getFormatsFromElement: (element) => {
    let accept = element.attr('accept');
    return !!accept ? accept.replace(/[\.\s]/g, '').split(',').map((el) => { return el.toLowerCase(); }) : defaultFormats;
  },

  processFileFormat: (formats, file) => {
    if (!formats) formats = defaultFormats;
    return new Promise((resolve, reject) => {
      let fileReader = new FileReader();
      fileReader.onload = function() {
        let extension = fileType(new Uint8Array(this.result)).ext.toLowerCase();
        if (formats.indexOf(extension) == -1) reject(extension);
        else resolve(extension);
      };
      fileReader.readAsArrayBuffer(file);
    });
  }

};

export default MimeType;
