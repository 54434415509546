import { each } from 'lodash/fp';
import app from '../common_app.es6';
import defineRadius from '../heatmap_radius.es6';

app.directive('markupsV3', [ '$timeout', '$rootScope', ($timeout, $rootScope) => {
  return {
    restrict: 'AE',
    template:
      '<div class="survey-reactions-interface_image-canvas-wrapper" ng-style="topStyles()">' +
        '<div class="survey-reactions-interface_image-canvas-inner">' +
          '<img class="survey-reactions-interface_image" ng-src="{{ imageSrc | urlSafe }}" image-loaded="onImageLoad($event, $image)" />' +
          '<canvas class="survey-reactions-interface_canvas" mouse-down="areaClick($event)" />' +
          '<div class="widget-heatmap_label" ng-if="label.show">{{label.text}}</div>' +
          '<div class="widget-heatmap_value" ng-if="label.show" ng-class="label.class">{{label.value}}</div>' +
        '</div>' +
      '</div>',
    replace: true,
    scope: {
      imageSrc: '@',
      activeTab: '@',
      markupTool: '=',
      label: '=',
      threshold: '=',
      resolution: '=',
      colors: '=',
      markupOpacity: '@',
      setTopStyle: '=?'
    },
    link: ($scope, $element) => {
      const eachWithIndex = each.convert({ 'cap': false });

      const getThreshold = (threshold) => ((parseInt(threshold, 10) || 50) / 100);
      const getMinThreshold = (threshold) => ((1 - getThreshold(threshold)));
      const getMaxThreshold = (maxValue, threshold) => (maxValue * (2 - getThreshold(threshold)));

      const colors = $scope.colors || {};
      const greenColor = colors.positive || '#4ec09a';
      const redColor = colors.negative || '#ff7970';
      const orangeColor = colors.confusing || '#d0b050';
      const borderColor = '#000000';
      const frameColor = '#29A5FF';
      const defaultBorderOpacity = 0.9;
      const highlightOpacity = 0.6;

      let width = 0;
      let height = 0;

      let heatmap;
      let heatmapScale = 1;
      let heatmapResolution = $scope.resolution || 50;
      let heatmapRenderWidth = 0;

      $scope.list = () => {
        return $scope.markupTool.getMarkupComments();
      };

      $scope.topStyleConfig = { width: null, height: null };
      $scope.topStyles = () => {
        if (!$scope.setTopStyle || !$scope.topStyleConfig.width) {
          return {};
        }
        return { width: $scope.topStyleConfig.width };
      };

      $scope.calcImageParams = () => {
        const $img = $element.find('.survey-reactions-interface_image');
        width = $img.outerWidth();
        height = $img.outerHeight();
        heatmapRenderWidth = width;
        heatmapScale = $img.outerWidth() / heatmapRenderWidth;
      };

      $scope.onImageLoad = function (ev, image) {
        const cInt = setInterval(() => {
          $scope.calcImageParams();

          clearInterval(cInt);
          const $canvas = $element.find('canvas.survey-reactions-interface_canvas');
          $canvas.attr('width', width).attr('height', height);

          $scope.markupTool.setWidth(width);

          $rootScope.$broadcast('image-loaded');
          $rootScope.$broadcast('markups-image-loaded', image);
          render();
          renderHeatmap();
          if ($scope.setTopStyle) {
            $timeout(() => {
              $scope.topStyleConfig.width = image.naturalWidth || width;
              $scope.topStyleConfig.height = image.naturalHeight || height;
            });
          }
        }, 100);
      };

      const getScaledArea = (baseWidth, area, image_width) => {
        const scale = baseWidth / parseFloat(image_width);
        const scaledArea = {
          left: parseFloat(area.left) * scale,
          top: parseFloat(area.top) * scale,
          width: parseFloat(area.width) * scale,
          height: parseFloat(area.height) * scale
        };
        scaledArea.right = scaledArea.left + scaledArea.width;
        scaledArea.bottom = scaledArea.top + scaledArea.height;
        return scaledArea;
      };

      const drawMarkup = (ctx, comment, alpha, frameRect, anyHighlighted) => {
        eachWithIndex((area) => {
          if (area.isHighlighted) {
            ctx.globalAlpha = highlightOpacity;
          } else if (anyHighlighted) {
            ctx.globalAlpha = 0.00000000000001;
          } else {
            ctx.globalAlpha = alpha;
          }

          if (comment.reaction === 'positive') {
            ctx.fillStyle = greenColor;
          }
          if (comment.reaction === 'negative') {
            ctx.fillStyle = redColor;
          }
          if (comment.reaction === 'confusing') {
            ctx.fillStyle = orangeColor;
          }

          const scaledArea = getScaledArea(width, area, comment.image_width);

          ctx.fillRect(scaledArea.left, scaledArea.top, scaledArea.width, scaledArea.height);

          if (area.isSelected) {
            ctx.globalAlpha = defaultBorderOpacity;
            ctx.strokeStyle = borderColor;
            ctx.strokeRect(scaledArea.left, scaledArea.top, scaledArea.width, scaledArea.height);

            const newFrame = {};

            if (frameRect.left == null || frameRect.left > scaledArea.left) {
              newFrame.left = scaledArea.left;
            } else {
              newFrame.left = frameRect.left;
            }

            if (frameRect.top == null || frameRect.top > scaledArea.top) {
              newFrame.top = scaledArea.top;
            } else {
              newFrame.top = frameRect.top;
            }

            if (frameRect.width == null || (frameRect.left + frameRect.width) < (scaledArea.left + scaledArea.width)) {
              if (frameRect.width == null) {
                newFrame.width = scaledArea.width;
              } else {
                newFrame.width = (scaledArea.left + scaledArea.width) - newFrame.left;
              }
            } else {
              newFrame.width = (frameRect.left + frameRect.width) - newFrame.left;
            }

            if (frameRect.height == null || (frameRect.top + frameRect.height) < (scaledArea.top + scaledArea.height)) {
              if (frameRect.height == null) {
                newFrame.height = scaledArea.height;
              } else {
                newFrame.height = (scaledArea.top + scaledArea.height) - newFrame.top;
              }
            } else {
              newFrame.height = (frameRect.top + frameRect.height) - newFrame.top;
            }

            frameRect.left = newFrame.left;
            frameRect.top = newFrame.top;
            frameRect.width = newFrame.width;
            frameRect.height = newFrame.height;
          }
          ctx.globalAlpha = 1;
        })(comment.filterAreas);
      };

      const render = () => {
        const canvas = $element.find('canvas')[0],
          context = canvas.getContext('2d');

        context.clearRect(0, 0, canvas.width, canvas.height);

        const list = $scope.list(),
          frameRect = { left: null, top: null, width: null, height: null };

        context.setLineDash([]);
        eachWithIndex((el) => {
          drawMarkup(context, el, $scope.markupOpacity, frameRect, $scope.markupTool.anyHighlighted);
        })(list);

        if (frameRect.left) {
          context.strokeStyle = frameColor;
          context.setLineDash([ 5, 2 ]);
          context.strokeRect(frameRect.left - 2, frameRect.top - 2, frameRect.width + 4, frameRect.height + 4);
          $rootScope.$broadcast('show-close-square', frameRect);
        } else {
          $rootScope.$broadcast('show-close-square', null);
        }
      };

      const destroyCanvas = () => {
        if (!heatmap) {
          return;
        }
        const canvas = heatmap._renderer.canvas;
        $(canvas).remove();
        heatmap = null;
      };

      const renderHeatmap = (recreate) => {
        if (typeof recreate == 'undefined') {
          recreate = true;
        }

        const heatmapAreaSizeX = heatmapRenderWidth / heatmapResolution,
          heatmapAreaSizeY = height / heatmapResolution;

        const nuConfig = {
          container: $element.find('.survey-reactions-interface_image-canvas-inner')[0],
          radius: defineRadius(heatmapAreaSizeX, heatmapAreaSizeY, heatmapResolution),
          maxOpacity: 0.5,
          minOpacity: 0,
          blur: 0.75,
          scale: heatmapScale
        };

        // TODO: Reconfiguring generates the heatmap other than created
        // if (recreate){
        destroyCanvas();
        heatmap = h337.create(nuConfig);
        // } else {
        //   heatmap.configure(nuConfig);
        // }

        changeCanvasOpacity($scope.activeTab);

        const list = $scope.list();
        const cells = [];
        let pX, pY;
        let maxCount = 0;

        const temp = [];
        for (temp; temp.push([]) < heatmapResolution;) {}

        eachWithIndex((comment) => {
          eachWithIndex((area) => {
            const scaledArea = getScaledArea(heatmapRenderWidth, area, comment.image_width);

            if (!!scaledArea && scaledArea.width) {
              const x1 = Math.floor(scaledArea.left / heatmapAreaSizeX), y1 = Math.floor(scaledArea.top / heatmapAreaSizeY),
                x2 = Math.floor((scaledArea.left + scaledArea.width) / heatmapAreaSizeX), y2 = Math.floor((scaledArea.top + scaledArea.height) / heatmapAreaSizeY);
              for (let i = x1; i <= x2; i++) {
                if (!temp[i]) {
                  temp[i] = [];
                }

                for (let j = y1; j <= y2; j++) {
                  if (temp[i][j] == undefined) {
                    temp[i][j] = { c: 0, id: null };
                  }
                  if (temp[i][j].id != comment.id) {
                    temp[i][j].c = temp[i][j].c + (comment.counter || 1);
                    temp[i][j].id = comment.id;
                  }
                }
              }
            }
          })(comment.filterAreas);
        })(list);

        for (let i = 0; i < heatmapResolution; i++) {
          for (let j = 0; j < heatmapResolution; j++) {
            if (temp[i][j]) {
              if (temp[i][j].c > maxCount) {
                maxCount = temp[i][j].c;
              }
              pX = i * heatmapAreaSizeX;
              pY = j * heatmapAreaSizeY;
              cells.push({
                value: temp[i][j].c,
                x: pX + heatmapAreaSizeX / 2,
                y: pY + heatmapAreaSizeY / 2
              });
            }
          }
        }

        if (heatmap) {
          heatmap.setData({ max: getMaxThreshold(maxCount, $scope.threshold), min: getMinThreshold($scope.threshold), data: cells });
        }
      };

      $scope.$on('collectionChanged', () => {
        heatmapResolution = $scope.resolution || 50;
        $scope.calcImageParams();
        render();
        renderHeatmap(false);
      });

      $scope.$on('imageChanged', () => {
        heatmapResolution = $scope.resolution || 50;
        render();
        renderHeatmap();
      });

      $scope.$on('respondentSelected', () => {
        render();
      });

      $scope.$on('respondentHidden', () => {
        render();
        renderHeatmap(false);
      });

      $scope.$on('thresholdChange', (event, newVal) => {
        if (newVal && heatmap) {
          heatmap.setDataMin(getMinThreshold(newVal));
          const data = heatmap.getData();
          const maxEl = data.data.max('value');
          if (maxEl) {
            heatmap.setDataMax(getMaxThreshold(maxEl.value, newVal));
          }
        }
      });

      $scope.$on('resolutionChange', (event, newVal) => {
        heatmapResolution = newVal;
        renderHeatmap(false);
      });

      $scope.$on('markupOpacityChange', (event, newVal) => {
        $scope.markupOpacity = newVal;
        render();
      });

      const changeCanvasOpacity = (newTab) => {
        $element.find('canvas.survey-reactions-interface_canvas').css('opacity', '0');
        $element.find('canvas.heatmap-canvas').css('opacity', '0');
        if (parseInt(newTab, 10) === 1) {
          $element.find('canvas.heatmap-canvas').css('opacity', '1');
        } else {
          $element.find('canvas.survey-reactions-interface_canvas').css('opacity', '1');
        }
      };

      $scope.$on('activeTabChange', (event, newTab) => {
        changeCanvasOpacity(newTab);
      });

      const prepareOffsets = (ev) => {
        if (ev.offsetX || !ev.touches) {
          return;
        }
        ev.offsetX = ev.touches[0].pageX - $(ev.touches[0].target).offset().left;
        ev.offsetY = ev.touches[0].pageY - $(ev.touches[0].target).offset().top;
      };

      $scope.$on('regions-click', (event, payload) => {
        $scope.areaClick(payload);
      });

      $scope.areaClick = (ev) => {
        prepareOffsets(ev);
        const firstCommentId = $scope.markupTool.areaClick(ev.offsetX, ev.offsetY);
        $rootScope.$broadcast('MarkupIsClicked');
        render();
        $rootScope.$broadcast('changeSelection', firstCommentId);
      };
    }
  };
} ]);
