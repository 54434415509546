import app from '../common_app.es6'

app.factory('comparisonsService', ['$http', '$rootScope', ($http, $rootScope) => {
  let currentCount, updated;
  const URL = '/researcher/comparison';
  const updateHeader = ({data}) => {
    currentCount = data.count;
    updated = data.updated;
    $rootScope.$broadcast('comparison_change', {count: data.count});
  };

  return {
    add: concept_id => $http.post(`${URL}/add`, {concept_id}).then(updateHeader),
    remove: concept_id => $http.delete(`${URL}/remove`, {params: {concept_id}}).then(updateHeader),
    currentCount: () => currentCount,
    updated: () => updated
  }
}]);