import app from '../common_app.es6';

app.directive('importSheetJs', [ '$rootScope', '$timeout', ($rootScope,$timeout) => {
  return {
    restrict: 'A',
    scope: {  },
    link: ($scope, $elm) => {

      $elm.on('change', function (changeEvent) {
        var reader = new FileReader();

        reader.onload = function (e) {
          var bstr = e.target.result,
              workbook = XLSX.read(bstr, {type:'binary'}),
              first_sheet_name = workbook.SheetNames[0],
              first_sheet = workbook.Sheets[first_sheet_name],
              json = XLSX.utils.sheet_to_json(first_sheet);

          json.headerStatement = first_sheet.A1.v;
          json.headerLabel = first_sheet.B1 ? first_sheet.B1.v : null;

          $timeout(()=>{
            $rootScope.$broadcast('xlsx-loaded', json);
          });

        };

        reader.readAsBinaryString(changeEvent.target.files[0]);
        changeEvent.target.value = "";
      });

    }
  }
}]);
