import app from '../common_app.es6';
import { capitalize, merge } from 'lodash';

app.factory('IframeMessages', [() => {
  let sendMessage = (content) => { window.parent.postMessage(content, "*"); };
  return {
      sendMessage: sendMessage,
      finishIdeaStreamSurvey: (tag) => { sendMessage("get" + capitalize(tag)); },
      finishSurvey: (content) => {sendMessage(merge({ finishSurvey: true }, content || {})) },
      updateQuestion: (content, callback) => {
        window.addEventListener("message", (ev) => {
          if (!ev.data) return;
          let hash = ev.data.updateQuestion;
          if (hash) {
            callback(hash, {
              hideHintsOnStart: ev.data.hideHintsOnStart,
              conceptsForSwipe: ev.data.conceptsForSwipe,
              conceptIdsForSwipe: ev.data.conceptIdsForSwipe
            });
          }
        });
        sendMessage(merge({ prepareQuestion: true }, content || {}))
      }
    }
}]);