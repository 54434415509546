import app from '../common_app.es6';

app.factory('ImageStatus', [() => {
  const items = ['errors', 'uploaded', 'full'];
  const defaultKinds = ['product', 'base'];
  let hash = {};

  return {
    hash: hash,
    clear: (kinds) => {
      if (!kinds) kinds = defaultKinds;
      if (typeof kinds != 'object') kinds = [kinds];
      for(var i in kinds) {
        let kind = kinds[i];
        if (!hash[kind]) hash[kind] = {};
        for(var j in items){ hash[kind][items[j]] = 0 }
      }
    },
    increase: (kind, itemName) => {
      hash[kind][itemName] += 1;
    },

    getValue: (kind, itemName) => {
      return hash[kind][itemName];
    }
  }
}]);