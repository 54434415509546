import app from '../common_app.es6';

app.directive('chosen', [ '$timeout', '$compile', ($timeout, $compile) => {
  return {
    restrict: 'A',
    require: '^ngModel',
    link: ($scope, $element, $attrs, ngModelCtrl) => {
      angular.element(document).ready(function () {

        $element.addClass('-customized').chosen({ inherit_select_classes: true, width: '100%' });

        if (ngModelCtrl.$viewValue) {
          $element.val(ngModelCtrl.$viewValue);
          $element.trigger('chosen:updated');
        }

        $element.on('change', (evt, params) => {
          $timeout(() => {
            ngModelCtrl.$setViewValue(params.selected);
            $element.trigger('chosen:updated');
          });
        });
      });
    }
  }
}]);
