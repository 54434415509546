import app from '../common_app.es6';

app.directive('cloud', [ '$timeout', '$compile', ($timeout, $compile) => {
  return {
    restrict: 'A',
    scope: { followup: '=cloud' },
    link: ($scope, $element, $attrs) => {
      // TODO: quickfix
      var c = setInterval(() => {
        if ($element.height() > 0 && $scope.followup.result.length > 0){
          clearInterval(c);
          $element.jQCloud($scope.followup.result);
        }
      }, 100);

    }
  }
}]);