import app from '../../common_app.es6';
require('../compiled_output.es6');

import { any, find, map, reject, pick } from "lodash/fp";
import { each } from "lodash";

app.directive('openEndCard', ['$http','$rootScope','$timeout', '$uibModal', 'DownloadMessaging',
  ($http, $rootScope, $timeout, $uibModal, DownloadMessaging) => {
  return {
    restrict: 'E',
    scope: {
      data: '=',
      metric: '=',
      mode: '=',
      filter: '=',
      hideClose: '=',
      pinCount: '@',
      customTitle: '@',
      hideExport: '@',
      answerHelp: '@',
      customOrientations: '&',
      customSelectedOrientation: '@',
      fontFamily: "@",
      pinTitle: '@',
      unpinTitle: '@'
    },
    template: require('./open_end_card_template.html'),
    controller: ['$scope', ($scope) => {

      $scope.pinCount = !$scope.pinCount ? 3 : parseInt($scope.pinCount, 10);

      const urls = {
        export: '/dashboard/api/open_ends/database_concepts/:concept_id/answers/export',
        filter: '/dashboard/api/open_ends/database_concepts/:concept_id/answers/filter',
        keywords: '/dashboard/api/open_ends/database_concepts/:concept_id/keywords?source_id=:source_id&source_type=:source_type',
        link: '/dashboard/api/open_ends/database_concepts/:concept_id/answers/link',
        unlink: '/dashboard/api/open_ends/database_concepts/:concept_id/answers/unlink',
        play: '/dashboard/api/open_ends/database_concepts/:concept_id/answers/play?linked_id=:answer_id&linked_type=:answer_type',
      };

      $scope.loading = false;

      $scope.lcKey = () => {
        return `openEndsSection${ $scope.data.id }`;
      };

      $scope.getNeutralTags = () => {
        return JSON.parse(window.localStorage.getItem($scope.lcKey()) || '[]');
      };

      $scope.neutral_tags = $scope.getNeutralTags();
      $scope.filtered_neutral_answers = null;

      if ($scope.customOrientations && $scope.customOrientations()){
        $scope.orientations = $scope.customOrientations()();
      }
      else {
        $scope.orientations = [
          { name:'vertical', label: 'Vertical wordcloud', width: 300, height: 500 },
          { name:'horizontal', label: 'Horizontal wordcloud', width: 400, height: 300 }
        ];
      }

      $scope.delete = () => {
        $http.delete($scope.metric.urls.delete).then(
          (response) => { $rootScope.$broadcast('open-end-deleted', response.data) },
          (error) => {}
        );
      };

      $scope.$watch(
        () => { return $scope.customSelectedOrientation ? $scope.customSelectedOrientation : $scope.metric.orientation },
        (orientation) => {
          $scope.selectedOrientation = find((o) => o.name == orientation, $scope.orientations);
      }, true);

      $scope.update = () => {
        $http.put($scope.metric.urls.update, pick(['sentiment', 'orientation'], $scope.metric)).then(
          (response) => {},
          (response) => { alertify.error(response.data.error || response.statusText);}
        );
      };

      $scope.editWordCloud = (kind) => {
        if (!kind) kind = 'neutral';

        const modalInstance = $uibModal.open({
          size: 'sm',
          windowClass: '-modal-choose-words',
          component: 'modalOpenEndKeywordsComponent',
          backdrop: 'static',
          resolve: {
            kind: () => kind,
            report: () => $scope.data,
            metric: () => $scope.metric,
            filter: () => $scope.filter
          }
        });
        modalInstance.result.then(
          (data) => {
            if( data.need_save ) {
              $scope.loading = true;
              $rootScope.$broadcast('needSentimentsUpdate', $scope.data.id);
            }
          },
          (dismiss) => {
            console.log('modal dismissed')
          }
        )
      };

      $scope.$on('stoppedGettingData', () => {
        $scope.loading = false;
      });

      $scope.loadKeywords = (q) => {
        let url = urls.keywords.replace(':concept_id', $scope.metric.concept_id).
                                replace(':source_id',  $scope.metric.source_id).
                                replace(':source_type',encodeURIComponent($scope.metric.source_type)),
            filterStr = map((filter) => { return `filter[]=${filter}` }, $scope.filter || []).join('&'),
            options = [`q=${q}`];
        if (!!filterStr) options.push(filterStr);
        return $http.get(`${url}&${options.join('&')}`);
      };

      $scope.filteredNeutralAnswers = (recalc) => {
        if($scope.filtered_neutral_answers == null || recalc){
          $scope.filtered_neutral_answers = map((answer) => {
            const selectedNeutralAnswer = find((el) => answer.id == el.id, $scope.data.neutral_answers);
            answer.hidden = !!selectedNeutralAnswer;
            return answer;
          }, $scope.data.all_neutral_answers);
        }
        return $scope.filtered_neutral_answers;
      };

      $scope.playAnswer = (answer) => {
        answer.playing = true;
        const audio = document.getElementById(`play-${answer.answer_id || answer.id}`);
        audio.src = urls.play.
                         replace(':concept_id', $scope.data.concept_id).
                         replace(':answer_id',  answer.id).
                         replace(':answer_type',encodeURIComponent(answer.type));

        audio.addEventListener('ended', () => { $timeout(() => { answer.playing = false }) });
        audio.play()
      };

      $scope.stopPlay = (answer) => {
        const audio = document.getElementById(`play-${answer.answer_id || answer.id}`);
        audio.pause();
        audio.currentTime = 0.0;
        answer.playing = false;
      };

      $scope.addKeyword = (kind, text) => {
        let alreadyPresent = find({ text: text }, $scope[`${kind}_tags`]);
        if (alreadyPresent) return false;

        $scope[`${kind}_tags`].push({text});
        $scope.filterAnswers(kind)
      };

      $scope.filterAnswers = (kind) => {
        if (!kind) kind = 'neutral';

        const keywords = $scope[`${kind}_tags`];

        if (keywords.length > 0) {
          let q = map('text', keywords).join('|');

          $http.post(
            urls.filter.replace(':concept_id', $scope.data.concept_id),
            {
              source_id: $scope.metric.source_id,
              source_type: $scope.metric.source_type,
              q: q,
              filter: ($scope.filter || [])
            }
          ).then(
            (response) => {
              $scope[`filtered_${kind}_answers`] = reject(answer => any(linked => linked.id === answer.id)($scope.data[`${kind}_answers`]))(response.data);
              each($scope.data[`${kind}_answers`], (el) => {
                el.filtered = !find((subEl) => { return el.id == subEl.id }, response.data);
              });
            },
            (error) => {}
          )
        } else {
          $scope[`filtered_${kind}_answers`] = reject(answer => any(linked => linked.id == answer.id)($scope.data[`${kind}_answers`]))($scope.data[`all_${kind}_answers`]);
          each($scope.data[`${kind}_answers`], (el) => { el.filtered = false; });
        }
      };

      $scope.exportVerbatims = (event) => {
        let url = urls.export.replace(':concept_id', $scope.data.concept_id);

        $http.post(url,
          {
            source_id: $scope.metric.source_id,
            source_type: $scope.metric.source_type,
            filter: $scope.filter,
            q: map('text', $scope.neutral_tags).join('|')
          }
        ).then(
          () => { DownloadMessaging.downloadStarts(event); },
          (error) => {}
        );
      };

      $scope.linkAnswer = (answer) => {
        let url = urls.link.replace(':concept_id', $scope.data.concept_id);

        $http.post(
          url,
          {
            source_id: $scope.metric.source_id,
            source_type: $scope.metric.source_type,
            linked_id: answer.id,
            linked_type: answer.type
          }
        ).then(
          (response) => {
            const linked = response.data;
            $scope.data.neutral_answers.push(linked);
            $scope.filteredNeutralAnswers(true);
          },
          (error) => {}
        );
      };

      $scope.unlinkAnswer = (answer) => {
        let url = urls.unlink.replace(':concept_id', $scope.data.concept_id);

        $http.post(
          url,
          {
            source_id: $scope.metric.source_id,
            source_type: $scope.metric.source_type,
            linked_id: answer.id,
            linked_type: answer.type
          }
        ).then(
          (response) => {
            const unlinked = response.data;
            $scope.data.neutral_answers = reject({id: unlinked.id, type: unlinked.type})($scope.data.neutral_answers);
            $scope.filteredNeutralAnswers(true);
          },
          (error) => {}
        );
      };

      $scope.updateOrientation = (orientation) => {
        $scope.metric.orientation = orientation;
        $scope.update();
      };

      $scope.onTagAddedToFilter = (kind) => {
        $scope.updateNeutralTags();
        $scope.filterAnswers(kind);
      };

      $scope.onTagRemoved = (kind) => {
        $scope.updateNeutralTags();
        $scope.filterAnswers(kind);
      };

      $scope.updateNeutralTags = () => {
        window.localStorage.setItem($scope.lcKey(), JSON.stringify($scope.neutral_tags));
      };

      $scope.$watch('data.all_neutral_answers', ()=>{
        $scope.filteredNeutralAnswers(true);
      });

      if (!!$scope.neutral_tags.length) $scope.filterAnswers();

      if (!$scope.fontFamily) $scope.fontFamily = "Century Gothic, Roboto, AppleGothic";

      $scope.state = {
        pinTitle: $scope.pinTitle || "Link",
        unpinTitle: $scope.unpinTitle || "Unlink",
      };

    }]
  }
}]);


