import app from '../common_app.es6';

app.directive('textEditor', [ '$timeout', ($timeout) => {
  return {
    scope: {
      model: '=textEditor',
      error: '=error'
    },
    link: ($scope, $element) => {

      $element.val($scope.model);

      $element = $element.wysihtml5({
        "image": false,
        "link": false,
        "color": true,
        "font-styles": false
      });

      var editor = $element.data('wysihtml5').editor;

      var iframe = editor.composer.iframe;
      if ($scope.error) $(iframe).addClass('-error');

      editor.on("change", () => { $timeout(() => { $scope.model = editor.getValue() }); });
      editor.on("aftercommand:composer", () => { $timeout(() => { $scope.model = editor.getValue() }); });
    }
  }
}]);