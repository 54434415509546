export default (areaX, areaY, resolution) => {
  const xBigger = areaX >= areaY;

  const division = xBigger ? (areaY / areaX) : (areaX / areaY);
  const coefficient2 = resolution * 2 / 100;
  const coefficient4 = resolution * 2.2 / 100;
  const coefficient48 = resolution * 2.3 / 100;
  const result = 2.0 * (xBigger ? areaX : areaY);

  if (resolution < 50 || division < 2) {
    return result;
  }

  if (division >= 4.8) {
    return result * coefficient48;
  } else if (division > 4) {
    return result * coefficient4;
  } else if (division >= 2) {
    return result * coefficient2;
  }
};
