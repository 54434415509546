import app from '../common_app.es6';

app.directive('wrongMime', [ () => {
  return {
    restrict: 'A',
    template:
      '<div class="wrong-mime" ng-show="status && status.errors > 0">' +
        'Only PNGs, JPEGs and GIFs are allowed ' +
        '<span class="common-hint">?</span>' +
      '</div>',
    replace: true,
    scope: { status: '=wrongMime', placement: '@' },
    link: function ($scope, $element) {
      $element.find('.common-hint').popover({
        title: 'You tried to upload a photo of unsupported format',
        content:
          "<div class='content-bold'>Only PNGs, JPEGs and GIFs are allowed</div>"+
          "If you’re trying to upload a valid image, but it is still not uploading, open this image in any photo editor "+
          "and use ‘Save as...’ PNG to make it a true png format.",
        placement: $scope.placement || 'auto bottom',
        trigger: 'hover',
        html: true
      });
    }
  }
}]);