window.sendPostMessage = function (event, customText) {
  var message = {
    kind: "DownloadMessage",
    x: event.clientX,
    y: event.clientY,
    offsetX: event.offsetX,
    offsetY: event.offsetY,
    iframe: window.parent != window
  };

  if (!!customText) message.customText = customText;

  (window.parent || window).postMessage(message, "*");
};

window.sendDownloadData = function (event, waitingElement, customText) {
  if (waitingElement)
    $(waitingElement).on ('ajax:success', function(){ window.sendPostMessage(event, customText) });
  else
    window.sendPostMessage(event, customText);
};

window.monitorIframeClick = function () {
  var handler = function() {
    (window.parent || window).postMessage({
      kind: "DownloadiFrameClick",
      iframe: window.parent != window
    }, "*");
  };

  document.addEventListener('touchstart', handler);
  document.addEventListener('mousedown', handler);
};

window.monitorIframeClick();

window.changeSearchR = function (newR) {
  if(window.parent != window){
    var h = window.parent.location.hash,
        parts = h.split('&'),
        index = -1;
    for(var i=0; i< parts.length; i++){
      if( parts[i].startsWith('r=') ){
        parts[i] = "r="+newR;
        index = i;
      }
    }
    if(index==-1){
      parts.push("r="+newR);
    }
    window.parent.location.hash = parts.join('&');
  }
};
