import app from '../common_app.es6';

app.directive('linkToComparison', [() => {
  return {
    restrict: 'E',
    scope: {
      count: '@',
    },
    template: require('./link_to_comparison_template.html'),
    link: ($scope) => {
      $scope.$on('comparison_change', function (event, {count}) {
        $scope.count = count
      })
    }
  }
}]);


