import app from '../common_app.es6';

app.directive('dragStart', [ '$timeout', '$parse', ($timeout, $parse) => {
  return {
    restrict: 'A',
    link: function ($scope, $elem, $attrs) {
      var fn = $parse($attrs.dragStart);
      $elem[0].ondragstart = function (event) { $timeout(() => { fn($scope, { $event: event }); }); };
    }
  }
}]);