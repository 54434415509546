import app from '../../common_app.es6';

app.directive('removeFormatButton', [ () => {
  return {
    restrict: 'A',
    template: require('./remove_format_button.html'),
    replace: true,
    link: function ($scope, $element) {

      $scope.removeFormatting = () => {
        const textarea = $element.parents('.wysiwyg-menu').next();
        textarea.html(textarea.text());
        textarea.trigger('paste');
      }
    }
  }
}]);