import app from '../common_app.es6';
import {
  closePopupMessage
} from '../../approach-react/express/common/sidebar/components/bottomMenu/downloadIndicator/helper';

app.directive('downloadIndicator', [ '$rootScope', '$http', '$timeout', 'DownloadMessaging',
  ($rootScope, $http, $timeout, DownloadMessaging) => {
    return {
      restrict: 'E',
      scope: { userId: '@' },
      template: require('./download_indicator_template.html'),
      link: ($scope, $element) => {
        const defaultInterval = 5000;
        $scope.downloadsEmpty = true;
        $scope.userDownloads = [];
        $scope.popup = { x: 0, y: 0, show: false, offsetX: 0, offsetY: 0 };

        DownloadMessaging.monitor((data) => {
          $timeout(() => {
            const wrapperPosition = $('.main-wrapper').position();

            data.x = data.iframe ? data.x + $('.project-sidebar_inset').width() + $('.sidebar_nav').width() : data.x;
            $scope.popup.x = data.x + $(document).scrollLeft();
            $scope.popup.y = data.y + $(document).scrollTop();
            $scope.popup.show = true;
            $scope.popup.offsetX = data.x - wrapperPosition.left;
            $scope.popup.offsetY = data.y - wrapperPosition.top;
            DownloadMessaging.showPopup($scope);
            $scope.checkStatus();
          });
        });

        DownloadMessaging.monitorIframeClick(() => {
          $($element.parents('.dropdown.-on-hover')[0]).removeClass('-hovered');
        });

        $scope.$on(closePopupMessage, () => {
          const topElement = $($element.parents('.dropdown.-on-hover')[0]);

          const handler = () => { topElement.removeClass('-hovered'); };
          document.addEventListener('touchstart', handler);
          document.addEventListener('mousedown', handler);

          topElement.addClass('-hovered');
        });

        $scope.finishedCount = () => {
          return $scope.userDownloads.count((el) => { return el.status == 'finished' || el.status == 'failed'; });
        };

        $scope.checkStatus = (forceOneTime = false) => {
          $http.get('/dashboard/user_downloads.json').then(
            (response) => {
              $scope.userDownloads = response.data.user_downloads;
              $scope.downloadsEmpty = $scope.userDownloads.length == 0;
              $scope.anyInProgress = response.data.any_in_progress;

              if (!forceOneTime && $scope.anyInProgress) {$timeout($scope.checkStatus, defaultInterval);}
            },
            () => {}
          );
        };

        $timeout($scope.checkStatus, 1000);
      }
    };
  } ]);


