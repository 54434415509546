import app from '../common_app.es6';

app.filter('money', function () {
  return function(price, currency) {
    if (!currency) { currency = { sign: '$', placement: 'left' } }
    price = price.toFixed(2);

    return currency.placement == 'left' ? currency.sign + price : price + ' ' + currency.sign;
  };
});
