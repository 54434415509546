import app from '../common_app.es6';

app.directive('sortableHeader', [ '$rootScope', ($rootScope) => {
  return {
    restrict: 'A',
    template:
      '<th><a class="sort-link" ng-class="{\'-asc\': dir == \'asc\', \'-desc\': dir == \'desc\'}" ng-click="change()">{{title}}</a></th>',
    replace: true,
    scope: {
      title: "@sortableHeader",
      field: "@",
      sorting: "=",
    },
    link: function ($scope, $element) {

      // console.log($scope.field, $scope.sorting);

      $scope.dir = '';
      if($scope.sorting['sort'] == $scope.field){
        $scope.dir = $scope.sorting['dir'];
      }

      $scope.change = ()=>{

        if($scope.sorting['sort'] != $scope.field || !$scope.sorting['dir'] || $scope.sorting['dir'] == 'desc'  ){
          $scope.sorting['dir'] = 'asc';
        }else if($scope.sorting['dir'] == 'asc'){
          $scope.sorting['dir'] = 'desc';
        }
        $scope.sorting['sort'] = $scope.field;
        $scope.dir = $scope.sorting['dir'];
        $rootScope.$broadcast('sorting-changed');

      };

      $scope.$on('sorting-changed', () => {
        if($scope.sorting['sort'] != $scope.field){
          $scope.dir = '';
        }
      });

    }
  }
}]);