import app from '../common_app.es6';
import '@fancyapps/fancybox/dist/jquery.fancybox.min.js';

app.directive('fancyBox', [ () => {
  return {
    restrict: 'A',
    link: ($scope, $element, $attrs) => {
      if ($attrs['fancyBox'] != 'none'){

        $element.attr('data-fancybox', $attrs['fancyBox']);

        $('[fancy-box]').fancybox({
          // Options will go here
          // Should display counter at the top left corner
          infobar: false,
          // Should display close button (using `btnTpl.smallBtn` template) over the content
          smallBtn: false,
          // Should display toolbar (buttons at the top)
          toolbar: true,
          // Detect "idle" time in seconds
          idleTime: false,

          // What buttons should appear in the top right corner.
          // Buttons will be created using templates from `btnTpl` option
          // and they will be placed into toolbar (class="fancybox-toolbar"` element)
          buttons: [
            //"zoom",
            //"share",
            //"slideShow",
            //"fullScreen",
            //"download",
            //"thumbs",
            "close"
          ],
          image: {
            // Wait for images to load before displaying
            //   true  - wait for image to load and then display;
            //   false - display thumbnail and load the full-sized image over top,
            //           requires predefined image dimensions (`data-width` and `data-height` attributes)
            preload: true
          },
          // Open/close animation type
          // Possible values:
          //   false            - disable
          //   "zoom"           - zoom images from/to thumbnail
          //   "fade"
          //   "zoom-in-out"
          //
          animationEffect: "fade",
          // Duration in ms for open/close animation
          animationDuration: 100,

          // Should allow caption to overlap the content
          preventCaptionOverlap: false,
          // Base template for layout
          baseTpl:
          '<div class="fancybox-container" role="dialog" tabindex="-1">' +
          '<div class="fancybox-bg"></div>' +
          '<div class="fancybox-inner">' +
          '<div class="fancybox-navigation">{{arrows}}</div>' +
          '<div class="fancybox-toolbar">{{buttons}}</div>' +
          '<div class="fancybox-caption"><div class="fancybox-caption__body"></div></div>' +
          '<div class="fancybox-stage"></div>' +
          '</div>' +
          '</div>',

          btnTpl: {
            // Arrows
            arrowLeft:
              '<button data-fancybox-prev class="fancybox-button fancybox-button--arrow_left" title="{{PREV}}"></button>',

            arrowRight:
              '<button data-fancybox-next class="fancybox-button fancybox-button--arrow_right" title="{{NEXT}}"></button>'
          }
        });
      }
    }
  }
}]);