import app from '../common_app.es6';

app.directive('importIndicator', [ '$rootScope', '$uibModal', '$http', '$timeout', ($rootScope, $uibModal, $http, $timeout) => {
  return {
    restrict: 'E',
    scope: {
      urlProjects: '@urlProjects',
      urlStart: '@urlStart',
      urlIndicator: '@urlIndicator',
      clients: '=clients',
      initial: '=initial',
      defaultLoopField: '@'
    },
    template: require('./import_indicator_template.html'),
    link: ($scope) => {

      const interval = 3000;

      $scope.openModal = () => {
        if ($scope.showIndicator) {return;}

        const mapper = $uibModal.open({
          template: require('./import_indicator_modal_template.html'),
          controller: 'ImportModalController',
          size: 'lg',
          resolve: {
            data() {
              return {
                urlProjects: $scope.urlProjects,
                urlStart: $scope.urlStart,
                clients: $scope.clients,
                defaultLoopField: $scope.defaultLoopField
              };
            }
          }
        });

        mapper.result.then(
          (result) => {
            // console.log("success",result);
          },
          (result) => {
            // console.log("cancel", result);
          }
        );
      };

      $scope.showIndicator = false;
      $scope.processed = 0;
      $scope.total = 0;
      $scope.projects = [];

      $scope.$on('start-indication', () => { $scope.checkStatus(false); });
      $scope.$on('syncRespondentsCheck', () => { $scope.checkStatus(true); });

      $scope.checkStatus = (forceSync) => {
        $http.get(
          $scope.urlIndicator
        ).then(
          (response) => {
            $scope.processed = response.data.processed;
            $scope.total = response.data.total;
            $scope.projects = response.data.projects;

            if (response.data.processed == response.data.total) {
              $scope.showIndicator = false;
            } else {
              $scope.showIndicator = true;
              if (!forceSync) {$timeout($scope.checkStatus, interval);}
            }

            $rootScope.$broadcast('show-import-icons', response.data);
          }
        );
      };

      if ($scope.initial.processed != $scope.initial.total) {
        $timeout($scope.checkStatus, interval);
        $rootScope.$broadcast('show-import-icons', $scope.initial);
      } else {
        $scope.projects = $scope.initial.projects;
      }
    }
  };
} ]);


