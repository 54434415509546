import app from '../common_app.es6';

app.directive('imageInPopup', [ '$timeout', ($timeout) => {
  return {
    restrict: 'A',
    scope: { imageUrl: '@imageInPopup', imageClass: '@imageClass' },
    link: ($scope, $element) => {
      if ($scope.imageUrl){
        if (!$scope.imageClass) $scope.imageClass = 'actions-path-item_tooltip-image';

        $element.tooltip({
          placement: 'auto top',
          title: $('<img/>', { src: $scope.imageUrl, alt: '' }).addClass($scope.imageClass),
          html: true,
          container: 'body',
          template:
          '<div class="tooltip -contains-image" role="tooltip">' +
            '<div class="tooltip-arrow"></div>' +
            '<div class="tooltip-inner"></div>' +
          '</div>'
        });
      }
    }
  }
}]);