import app from '../../common_app.es6';
import '../info_sender.js';
import {
  downloadIframeClickMessage,
  downloadMessage
} from '../../../approach-react/express/common/sidebar/components/bottomMenu/downloadIndicator/helper';

app.factory('DownloadMessaging', [ '$compile', ($compile) => {
  let popupAdded = false;

  return {
    downloadStarts: (event, customText) => { window.sendDownloadData(event, null, customText); },
    monitor: (callback) => {
      window.addEventListener("message", (ev) => {
        if (!ev.data) {return;}
        if (typeof (ev.data) != 'object') {return;}
        if (ev.data.kind != downloadMessage) {return;}
        callback(ev.data);
      });
    },
    monitorIframeClick: (callback) => {
      window.addEventListener("message", (ev) => {
        if (!ev.data) {return;}
        if (typeof (ev.data) != 'object') {return;}
        if (ev.data.kind != downloadIframeClickMessage) {return;}
        callback(ev.data);
      });
    },
    showPopup: (scope) => {
      if (popupAdded) {return;}
      const element = `${"<download-popup " +
                      " x='popup.x' y='popup.y' " +
                      " show='popup.show' offset-x='popup.offsetX' offset-y='popup.offsetY' "}${
        scope.popup.customText ? ` custom-text='${scope.popup.customText}' ` : ""
      } />`;
      const output = $compile(element)(scope);
      $('body').append(output);
      popupAdded = true;
    }
  };
} ]);
