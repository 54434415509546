export default function (ev) {
  if (ev.clientX || (!ev.touches && !ev.changedTouches)) return;
  if (ev.touches.length) {
    if (!ev.clientX) ev.clientX = ev.touches[0].clientX;
    if (!ev.clientY) ev.clientY = ev.touches[0].clientY;
    if (!ev.pageX) ev.pageX = ev.touches[0].pageX;
    if (!ev.pageY) ev.pageY = ev.touches[0].pageY;
    if (!ev.screenX) ev.screenX = ev.touches[0].screenX;
    if (!ev.screenY) ev.screenY = ev.touches[0].screenY;
  }
  else if (ev.changedTouches.length){
    if (!ev.clientX) ev.clientX = ev.changedTouches[0].clientX;
    if (!ev.clientY) ev.clientY = ev.changedTouches[0].clientY;
    if (!ev.pageX) ev.pageX = ev.changedTouches[0].pageX;
    if (!ev.pageY) ev.pageY = ev.changedTouches[0].pageY;
    if (!ev.screenX) ev.screenX = ev.changedTouches[0].screenX;
    if (!ev.screenY) ev.screenY = ev.changedTouches[0].screenY;
  }
}