// based on https://github.com/xrd/ng-rails-csrf

import angular from 'angular'

const ngRailsCsrf = angular.module('ng-rails-csrf', []).config(['$httpProvider', ($httpProvider) => {
  let headers = $httpProvider.defaults.headers.common
  headers['X-CSRF-TOKEN'] = angular.element(document.querySelector('meta[name="csrf-token"]')).attr('content')
  headers['X-Requested-With'] = 'XMLHttpRequest'
}])

export default ngRailsCsrf
