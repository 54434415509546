import app from '../common_app.es6';
import d3 from '../d3.js';
import { compose, sum, values, map, keys, pick } from 'lodash/fp';

app.directive('donutChart', ['FontLoader', (FontLoader) => {
  return {
    restrict: 'E',
    scope: { stats: '=' },

    link: ($scope, $element, $attrs) => {
      const width = $attrs.width || 300;
      const hideValues = $attrs.hideValues || false;
      const height = $attrs.height || 300;
      const radius = Math.min(width, height) / 2;
      const fontFamily = $attrs.fontFamily || "Century Gothic, AppleGothic";
      const ringPart = $attrs.ringPart || 0.333;
      const keyFont = radius * ($attrs.keyFont || 0.1);
      const percFont = radius * ($attrs.percFont || 0.2);
      const sameColor = $attrs.sameColor || false;
      const makeCaps = $attrs.makeCaps || false;

      let capitalize = function(input) {
        return (!!input) ? input.charAt(0).toUpperCase() + input.substr(1).toLowerCase() : '';
      };

      const COLORS = {
        positive: $attrs.positiveColor || '#00812e',
        neutral: $attrs.neutralColor || '#ffbc3c',
        negative: $attrs.negativeColor || '#ed1c24'
      };

      const arc = d3.svg.arc()
        .outerRadius(radius)
        .innerRadius(radius * (1-ringPart));

      const pie = d3.layout.pie()
        .value(d => d.value)
        .sort(null);

      let draw = (stats)=>{
        const total = compose(sum, values, pick(['negative', 'neutral', 'positive']))(stats);
        let data = total > 0
          ? compose(
            map(key => ({key, color: COLORS[key], value: Math.round(100 * (stats[key] || 0) / total)})),
            keys
          )(COLORS)
          : [{key: 'n/a', color: $attrs.emptyColor || '#919aa9', value: 1}];

        const svg = d3.select($element[0]);
        svg.select('svg').remove();

        const chart = svg.append("svg")
            .attr("width", width)
            .attr("height", height)
          .append("g")
            .attr("transform", `translate(${width / 2}, ${height / 2})`)
          .selectAll(".arc")
            .data(pie(data))
            .enter();

        chart.append("g")
            .attr("class", "arc")
          .append("path")
            .attr("d", arc)
            .style("fill", d => d.data.color);

        if (total > 0) {
          const text = chart.append("text")
            .attr("transform", (d, i) => `translate(0, ${2.1 * (i-1.6) * percFont})`)
            .attr("text-anchor", "middle");

          if ( hideValues == false ) {
            text.append('tspan')
            .attr("x", 0)
            .attr("dy", `${percFont}px`)
            .style("fill", d => d.data.color)
            .style("font-size", () => `${keyFont}px` )
            .text(d => makeCaps ? capitalize(d.data.key) : d.data.key);

            text.append('tspan')
            .attr("x", 0)
            .attr("dy", `${percFont}px`)
            .style("fill", d => sameColor ?  d.data.color : '#606772' )
            .style("font-size", () => `${percFont}px` )
            .text(d => d.data.value ? `${d.data.value}%` : '0%' )
          }
        }
      };

      $scope.$watch('stats', data => {
        if(hideValues){
          draw(data);
        }else{
          FontLoader([fontFamily], () => { draw(data) })
        }
      });
    }
  }
}]);
