import AWS from 'aws-sdk';
import MimeType from './mime_type.es6';

export default class S3Upload{

  constructor (accessKeyId, secretAccessKey, bucket, filePath, element, options = {}) {
    this.isTest = window.globalConfig && window.globalConfig.isTest();

    this.entity_name = options['entity_name'] || 'image';

    AWS.config.update({ accessKeyId: accessKeyId, secretAccessKey: secretAccessKey });
    this.s3 = new AWS.S3({ params: { Bucket: bucket }});
    this.element = $(element);
    this.formats = MimeType.getFormatsFromElement(this.element);
    this.filePath = filePath;
    if (!this.filePath) this.filePath = (filename) => { return this.entity_name + '_upload/'+ Date.now() + '/' + filename };
  }

  setupUpload (callbackStart, callbackFinish, callbackLimit, callbackBeginLoad){ this.addEvents(callbackStart, callbackFinish, callbackLimit, callbackBeginLoad); }

  uploadToS3(image, file, base64, callbackFinish) {
    var key = this.filePath(file.name);
    var contentType = file.type;
    var params = { Key: key, ContentType: file.type,  Body: file, ACL: 'public-read' };

    Object.merge(image, {
      [this.entity_name + "_file_name"]: key,
      [this.entity_name + "_content_type"]: contentType,
      [this.entity_name + "_file_size"]: file.size
    });

    if (this.isTest)
    {
      callbackFinish && callbackFinish(false, image, base64, {})
    }
    else {
      this.s3.putObject(params, function (err, data) {
        if (err) callbackFinish && callbackFinish(true, image, key, data);
        else {
          var host = this.request.httpRequest.endpoint.host;
          var path = this.request.httpRequest.path;
          var image_url = 'https://'+ host + path;
          callbackFinish && callbackFinish(false, image, image_url, data);
        }
      });
    }
  };

  addEvents (callbackStart, callbackFinish, callbackLimit, callbackBeginLoad) {
    var _this = this;
    this.element.on('change', (ev) => {
      if (!ev.target.value) return;
      var files = ev.target.files;
      var limit = files.length;

      if (callbackLimit && callbackLimit()) limit = callbackLimit();
      if (files.length < limit) limit = files.length;

      if (callbackBeginLoad && files.length ) callbackBeginLoad(files);

      for (var i = 0; i < limit; i++) {
        var file = files[i];
        var reader = new FileReader();
        reader.file = file;
        reader.onload = function(){
          let base64 = this.result;
          let image = callbackStart && callbackStart(base64);

          MimeType.processFileFormat(_this.formats, this.file).then(
            () => { _this.uploadToS3(image, this.file, base64, callbackFinish); },
            () => { callbackFinish && callbackFinish(true, image, base64, {}); }
          );
        };
        reader.readAsDataURL(file);
      }
    });
  }

}