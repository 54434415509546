import app from '../common_app.es6';

app.directive('hintInPopup', [ '$timeout', ($timeout) => {
  return {
    restrict: 'A',
    scope: { position: '@', hintClass: "@" },
    link: ($scope, $element, $attrs) => {
      let attached = false;

      let attachTooltip = () => {
        $element.tooltip({
          placement: $scope.position || 'auto top',
          container: 'body',
          title: $attrs.hintInPopup,
          template:
          `<div class="tooltip -contains-help ${$scope.hintClass||''}" role="tooltip">` +
          '<div class="tooltip-arrow"></div>' +
          '<div class="tooltip-inner"></div>' +
          '</div>'
        });
        attached = true;
      };

      let fixTitle = () => {
        $element.attr('data-original-title', $attrs.hintInPopup);
        $element.tooltip('fixTitle');
      };

      $scope.$watch(() => { return $attrs.hintInPopup }, () => {
        if (!!$attrs.hintInPopup)
          attached ? fixTitle() : attachTooltip();
      });

      $scope.$on('$destroy', function() {
        $('.tooltip').remove();
      });
    }
  }
}]);