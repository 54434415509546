import app from '../common_app.es6';
import Hammer from 'hammerjs';

app.directive('swipe', ['$timeout', '$rootScope', ($timeout, $rootScope) => {
    return {
        scope: { likeColor: "@", dislikeColor: '@' },
        link: ($scope, $element) => {
          var slider = $element[0],
              hammer = new Hammer.Manager(slider),
              pan = new Hammer.Pan(),
              edgePercentageToScore = 70,
              edgeMovementToScore = 20;

          hammer.add(pan);

          const hexToRgb = (hex) => {
            return hex.replace(/^#?([a-f\d])([a-f\d])([a-f\d])$/i
              , (m, r, g, b) => '#' + r + r + g + g + b + b)
              .substring(1).match(/.{2}/g)
              .map(x => parseInt(x, 16));
          };

          const setCorrectStyle = (element, rgbColor, opacity, rotation, movement) => {
            $(element).css({
              'transform': `rotate(${rotation}deg) translate(${movement}px)`,
              'background-color': `rgba(${rgbColor[0]}, ${rgbColor[1]}, ${rgbColor[2]}, ${opacity})`,
              'color': `rgba(${rgbColor[0]}, ${rgbColor[1]}, ${rgbColor[2]}, ${opacity})`
            });
          };

          var rotation = 0,
              movement = 0,
              old_delta = 0,
              default_color = hexToRgb('#FFFFFF'),
              like_color = hexToRgb($scope.likeColor || '#4EC09A'),
              dislike_color = hexToRgb($scope.dislikeColor || '#FF7970'),
              movement_percentage = 0,
              color,
              panStarted = false;

          var onPanEnd = (event) => {
            event.preventDefault();
            if(!panStarted){return;}
            panStarted = false;
            if (Math.abs(movement_percentage) >= edgePercentageToScore &&
                Math.abs(movement) >= edgeMovementToScore
              ) {
              Hammer(slider).off('pan');
              $rootScope.$broadcast('swipeEnd', movement > 0);
              document.removeEventListener('mouseleave', onPanEnd)
            }
            else {
              movement = 0;
              rotation = 0;
              movement_percentage = 0;
              old_delta = 0;

              $(slider).removeClass('-sliding');

              color = default_color;
              setCorrectStyle(slider, color, 1, 0, 0);

            }
          };

          hammer.on('pan', function (event) {
              event.preventDefault();
              movement += event.deltaX - old_delta;
              rotation = movement / $(window).width() * 10;
              var sign = movement > 0 ? 1 : -1;
              movement_percentage = sign * Math.round((($element.outerWidth() / 2 + Math.abs(movement)) / ($(window).width() / 2) * 100));
              old_delta = event.deltaX;

              let opacity = Math.abs(movement_percentage / 100);
              color = movement > 0 ? like_color : dislike_color;

              setCorrectStyle(slider, color, opacity, rotation, movement);

          }).on('panstart', function (event) {
            event.preventDefault();

            $(slider).addClass('-sliding');
            panStarted = true;

          }).on('panend', onPanEnd);

          $('body').on('mouseleave', (event)=>{
            if(panStarted) onPanEnd(event);
          });
        }
    }
}]);
