import app from '../common_app.es6';
import { map, uniq } from 'lodash/fp'
import { intersection, forEach, filter } from 'lodash'

app.component('modalOpenEndKeywordsComponent', {
  template: require('./modal_keywords_template.html'),
  bindings: {
    resolve: '<',
    close: '&',
    dismiss: '&'
  },
  controller: ['$scope', '$http', '$timeout', function ($scope, $http, $timeout) {
    let $ctrl = this;

    $scope.keyword_details = null;
    $scope.current_merge = {
      selected: ''
    };

    $scope.TYPE_TITLES = {neutral: 'Described As', positive: 'What Works', negative: 'What Doesn\'t Work' };

    $ctrl.$onInit = () => {
      $scope.kind = $ctrl.resolve.kind;
      $scope.report = $ctrl.resolve.report;
      $scope.filters = $ctrl.resolve.filter;
      $scope.metric = $ctrl.resolve.metric;
      $scope.question = $scope.report[`${$scope.kind}_question`];
      $scope.metric_result = $scope.question;

      $scope.load();
    };

    $scope.load = (q = null) => {
      $scope.loading = true;

      let params = {
        source_id: $scope.metric.source_id,
        source_type: $scope.metric.source_type,
        filters: $scope.filters,
        q: q
      };

      $http.get(`/dashboard/api/keywords.json?`+$.param(params)).then(
        (response) => {
          $scope.keywords = response.data;
        }, (response) => {
          alertify.error(response.data.error || response.statusText);
        }).finally(() => {
          $scope.loading = false;
        });
    };

    $scope.update = (keyword) => {
      if( !keyword.is_root ) {
        return null
      }

      keyword.updating = true;

      let params = {
        ids: keyword.ids,
        keyword: { text: keyword.selected }
      };

      $http.put(`/dashboard/api/keywords.json`, params).then(
        () => {
          keyword.text = keyword.selected;
        }, (response) => {
          alertify.error(response.data.error || response.statusText);
        }).finally(() => {
          keyword.updating = false;
          keyword.edit = false;
      });
    };

    $scope.merge = (merge_name) => {
      let keywords = filter($scope.keywords, (k) => { return k.checked } );
      forEach(keywords,  (k) => { k.updating = true } );

      let params = {
        source_id: $scope.metric.source_id,
        source_type: $scope.metric.source_type,
        keyword: merge_name,
        ids: keywords.map( (k) => k.ids )
      };

      $http.post(`/dashboard/api/keywords/merge.json`, params).then(
        (response) => {
          $scope.keywords = response.data;
        }, (response) => {
          alertify.error(response.data.validation_errors.join(', ') || response.data.error || response.statusText);
        }).finally(() => {
        forEach(keywords, (k) => { k.updating = false } );
        $scope.deselectAll();
      });
    };

    $scope.unmerge = (keyword) => {
      if($scope.keyword_details && $scope.keywordDetailsChildrenCount() <= 2 ){
        keyword = $scope.keyword_details;
      }

      keyword.updating = true;

      let params = {
        ids: keyword.ids
      };

      $http.post(`/dashboard/api/keywords/unmerge.json`, params).then(
        () => {
          if ( keyword.is_root ) {
            $scope.outFromGroup();
            $scope.load();
          } else {
            keyword.pids = [];
            if ($scope.keywordDetailsNoChildKeywords()) {
              $scope.outFromGroup();
              $scope.load();
            }
          }
        }, (response) => {
          alertify.error(response.data.error || response.statusText);
        }).finally(() => {
          keyword.updating = false;
        });
    };

    $scope.toggleHide = (keyword) => {
      if ( keyword.updating ) { return; }

      keyword.updating = true;

      let params = {
        hidden: !keyword.hidden,
        ids: keyword.ids
      };

      $http.post(`/dashboard/api/keywords/hide.json`, params).then(
        () => {
          keyword.hidden = !keyword.hidden
        }, (response) => {
          alertify.error(response.data.error || response.statusText);
        }).finally(() => {
          keyword.updating = false;
        });
    };

    $scope.isKeywordsSelected = () => {
      if( $scope.isInitialized() ) return false;
      return $scope.keywords.findAll( (k) => k.checked ).length > 1
    };

    $scope.deselectAll = () => {
      forEach($scope.keywords, (k) => { k.checked = false } );
    };

    $scope.getHeader = () => {
      return $scope.TYPE_TITLES[$scope.kind];
    };

    $scope.hiddenKeywordCount = () => {
      if( $scope.isInitialized() ) return 0;
      return filter($scope.keywords, (k) => { return k.hidden }).length
    };

    $scope.save = () => {
      $ctrl.close({$value: { need_save: true } });
    };

    $scope.attachBlur = ($select, keyword, getCollection) => {
      if ($select.attached) return;

      $select.searchInput.on('blur', () => {
        $timeout( () => { keyword.selected = getCollection($select.search)[0]; });
      });

      $select.searchInput.on('keydown', (event) => {
        if (event.which == 13) {
          event.preventDefault();
          event.stopPropagation();
          $timeout(() => {
            $select.search = $select.ngModel.$viewValue;
            keyword.selected = getCollection($select.ngModel.$viewValue)[0];
          });
        }
      });
      $select.attached = true;
    };

    $scope.filteredKeywordsDropdown = (input, filterCallback) => {
      let filtered = filter($scope.keywords, (e) => { return filterCallback(e); });

      let mapped = uniq( map((e) => { return e.text })(filtered) );

      if (!!input && mapped.indexOf(input) === -1) mapped.unshift(input);

      return mapped;
    };

    $scope.selectedKeywordsToMerge = (input) => {
      return $scope.filteredKeywordsDropdown(input, (e) => {
        return e.checked && (!!input ? !!e.text.match(new RegExp(input, 'i')) : true )
      });
    };

    $scope.childrenKeywords = (input, keyword) => {
      return $scope.filteredKeywordsDropdown(input, (e) => {
        return e.parent_id == keyword.id || e.id == keyword.id || $scope.childrenElements(e, keyword)
      });
    };

    $scope.cancelEdit = (keyword) => {
      keyword.edit = false;
      keyword.selected = null;
    };

    $scope.funcToChildrenSearch = (keyword) => {
      return (k) => $scope.childrenKeywords(k, keyword);
    };

    $scope.showKeyword = (keyword) => {
      $scope.keyword_details = keyword;
    };

    $scope.closeDetails = () => {
      $scope.keyword_details = null;
      $scope.load();
    };

    $scope.isInitialized = () => {
      return $scope.loading || typeof $scope.keywords === 'undefined';
    };


    $scope.isRootElement = (v) => {
      return !v.hidden && v.pids.length==0;
    };

    $scope.isHiddenRootElement = (v) => {
      return v.hidden && v.pids.length==0;
    };

    $scope.childrenElements = (v, rootKeyword) => {
      return !!rootKeyword && !!intersection(v.pids, rootKeyword.ids).length ;
    };

    $scope.isKeywordDetailsChildElement = (v) => {
      return $scope.childrenElements(v, $scope.keyword_details);
    };

    $scope.keywordDetailsChildrenCount = () => {
      return filter($scope.keywords, (item) => { return $scope.isKeywordDetailsChildElement(item) }).length;
    };

    $scope.keywordDetailsNoChildKeywords = () => {
      return !$scope.keywordDetailsChildrenCount();
    };

    $scope.outFromGroup = () => { $scope.showKeyword(null); };

    $scope.editOnKeywordDetails = (keyword) => {
      if (!keyword) keyword = $scope.keyword_details;
      keyword.selected = keyword.text;
      keyword.edit = true;
    }

  }]
});