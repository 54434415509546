import app from '../common_app.es6';
import { sortBy, reverse } from 'lodash/fp';
import defineRadius from '../heatmap_radius.es6';

app.directive('markupsV2', [ '$timeout', '$rootScope', ($timeout, $rootScope) => {
  return {
    restrict: 'AE',
    template:
      '<div class="survey-reactions-interface_image-canvas-wrapper" >'+
        '<img class="survey-reactions-interface_image" ng-src="{{ imageSrc | urlSafe }}" image-loaded="onImageLoad($event, $image)" />' +
        '<canvas class="survey-reactions-interface_canvas" mouse-down="areaClick($event)" />'+
        '<div class="widget-heatmap_label" ng-if="label.show">{{label.text}}</div>'+
        '<div class="widget-heatmap_value" ng-if="label.show" ng-class="label.class">{{label.value}}</div>'+
      '</div>',
    replace: true,
    scope: {
      imageSrc: '@',
      activeTab: '@',
      comments: '&',
      label: '=',
      threshold: '=',
      resolution: '=',
      colors: '='
    },
    link: ($scope, $element) => {

      var getThreshold = (threshold) => { return (parseInt(threshold, 10) || 50) / 100 },
          getMinThreshold = (threshold) => { return (1 - getThreshold(threshold)) },
          getMaxThreshold = (maxValue, threshold) => { return maxValue * ( 2 - getThreshold(threshold)) };

      var greenColor = $scope.colors.positive || '#4ec09a',
          redColor = $scope.colors.negative || '#ff7970',
          orangeColor = $scope.colors.confusing || '#d0b050',
          borderColor = '#000000',
          defaultBorderOpacity = 0.9,
          bigListDefaultOpacity = 0.02,
          littleListDefaultOpacity = 0.5,
          markupListTrigger = 1,

          width = 0,
          height = 0,

          heatmap,
          heatmapScale = 1,
          heatmapResolution = $scope.resolution||50 ,
          heatmapRenderWidth = 0,

          clipMode = 'none',
          clipRegions = [];

      $scope.list = (includeFilteredOut) => {
        return $scope.comments().call(this, includeFilteredOut);
      };

      $scope.filterAreas = (el)=>{
        if(clipMode == 'none'){
          return el.areas;
        }

        return el.areas.filter((area)=>{
          var i = $scope.intersectWithRegions(area, el.image_width);
          return clipMode == 'inside' && i == 1 || clipMode == 'outside' && i == -1 || clipMode == 'covered' && i == 0;
        });
      };

      $scope.onImageLoad = function(ev, image) {
        var c = setInterval(() => {
          var $img = $element.find('.survey-reactions-interface_image');

          width = $img.outerWidth();
          height = $img.outerHeight();
          heatmapRenderWidth = width;
          heatmapScale = $img.outerWidth()/heatmapRenderWidth;

          clearInterval(c);
          var $canvas = $element.find('canvas.survey-reactions-interface_canvas');
          $canvas.attr('width', width).attr('height', height);

          render();
          renderHeatmap();
        }, 100);
      };

      var getScaledArea = (baseWidth, area, image_width) => {
        var scale = baseWidth / parseFloat(image_width);
        var scaledArea = {
          left: parseFloat(area.left) * scale,
          top: parseFloat(area.top) * scale,
          width: parseFloat(area.width) * scale,
          height: parseFloat(area.height) * scale
        };
        scaledArea.right = scaledArea.left + scaledArea.width;
        scaledArea.bottom = scaledArea.top + scaledArea.height;
        return scaledArea;
      };

      var drawMarkup = (ctx, comment, alpha, border, borderOpacity) => {

        $scope.filterAreas(comment).each((area)=>{

          ctx.globalAlpha = alpha;

          if(comment.reaction=='positive') ctx.fillStyle = greenColor;
          if(comment.reaction=='negative') ctx.fillStyle = redColor;
          if(comment.reaction=='confusing') ctx.fillStyle = orangeColor;

          var scaledArea = getScaledArea(width, area, comment.image_width);

          ctx.fillRect(scaledArea.left, scaledArea.top, scaledArea.width, scaledArea.height);

          if (border){
            ctx.globalAlpha = borderOpacity;

            ctx.strokeStyle = borderColor;
            ctx.strokeRect(scaledArea.left, scaledArea.top, scaledArea.width, scaledArea.height);
          }
          ctx.globalAlpha = 1;

        });

      };

      var render = () => {
        var canvas = $element.find('canvas')[0],
            context = canvas.getContext('2d');

        context.clearRect(0, 0, canvas.width, canvas.height);

        let list = $scope.list(),
            defaultOpacity = list.length > markupListTrigger ? bigListDefaultOpacity : littleListDefaultOpacity;

        sortBy('selected', list).each((el)=>{
          let borderOpacity = el.selected ? defaultBorderOpacity : null;

          drawMarkup(context, el, defaultOpacity, !!borderOpacity, borderOpacity);
        })
      };

      var scoreCell = (pX, pY, cellWidth, cellHeight, list) => {
        var left = pX;
        var top = pY;
        var right = pX + cellWidth;
        var bottom = pY + cellHeight;

        var elements = list.filter(function(comment) {
          return $scope.filterAreas(comment).any((area)=>{
            var scaledArea = getScaledArea(heatmapRenderWidth, area, comment.image_width);
            return !( scaledArea.bottom <= top || bottom <= scaledArea.top || right <= scaledArea.left || scaledArea.right <= left )
          });
        });
        return elements.sum((e) => { return e.counter || 1});
      };

      var destroyCanvas = () => {
        if (!heatmap) return;
        var canvas = heatmap._renderer.canvas;
        $(canvas).remove();
        heatmap = null;
      };

      var renderHeatmap = (recreate) => {
        if (typeof recreate == 'undefined') recreate = true;

        var heatmapAreaSizeX = heatmapRenderWidth / heatmapResolution,
            heatmapAreaSizeY = height / heatmapResolution;

        var nuConfig = {
          container: $element[0],
          radius: defineRadius(heatmapAreaSizeX, heatmapAreaSizeY, heatmapResolution),
          maxOpacity: 0.5,
          minOpacity: 0,
          blur: 0.75,
          scale: heatmapScale
        };

        // TODO: Reconfiguring generates the heatmap other than created
        // if (recreate){
          destroyCanvas();
          heatmap = h337.create(nuConfig);
        // } else {
        //   heatmap.configure(nuConfig);
        // }

        changeCanvasOpacity($scope.activeTab);

        var cells = [],
            pX, pY,
            maxCount = 0,
            list = $scope.list();

        for(var i =0; i < heatmapResolution; i++) {
          for(var j = 0; j < heatmapResolution; j++) {
            pX = j * heatmapAreaSizeX;
            pY = i * heatmapAreaSizeY;
            var score = scoreCell(pX, pY, heatmapAreaSizeX, heatmapAreaSizeY, list);
            if (score) {
              if (score > maxCount) maxCount = score;
              cells.push({
                value: score,
                x: pX+heatmapAreaSizeX/2,
                y: pY+heatmapAreaSizeY/2
              })
            }
          }
        }
        if(heatmap){
          heatmap.setData({ max: getMaxThreshold(maxCount, $scope.threshold), min: getMinThreshold($scope.threshold), data: cells });
        }
      };

      $scope.$on('collectionChanged', () => {
        render();
        renderHeatmap(false);
      });

      $scope.$on('imageChanged', () => {
        heatmapResolution = $scope.resolution||50;
        render();
        renderHeatmap();
      });

      $scope.$on('respondentSelected', () => { render() });
      $scope.$on('respondentHidden', () => {
        render();
        renderHeatmap(false);
      });
      $scope.$on('thresholdChange', (event, newVal) => {
        if (newVal && heatmap){
          heatmap.setDataMin(getMinThreshold(newVal));
          var data = heatmap.getData();
          var maxEl = data.data.max('value');
          if (maxEl) heatmap.setDataMax(getMaxThreshold(maxEl.value, newVal));
        }
      });

      $scope.$on('resolutionChange', (event, newVal) => {
        heatmapResolution = newVal;
        renderHeatmap(false);
      });

      $scope.$on('new-clip-mode', (event, data) => {
        clipMode = data.mode;
        clipRegions = data.regions;

        $scope.list(true).each((comment) => {
          comment.filteredOut = $scope.filterAreas(comment).length == 0
        });

        render();
        renderHeatmap(false);
      });

      var changeCanvasOpacity = (newTab) => {
        $element.find('canvas.survey-reactions-interface_canvas').css('opacity', '0');
        $element.find('canvas.heatmap-canvas').css('opacity', '0');
        if (parseInt(newTab, 10) == 1) { $element.find('canvas.heatmap-canvas').css('opacity', '1') }
        else { $element.find('canvas.survey-reactions-interface_canvas').css('opacity', '1') }
      };

      $scope.$on('activeTabChange', (event, newTab) => { changeCanvasOpacity(newTab)});

      var prepareOffsets = (ev) => {
        if (ev.offsetX || !ev.touches) return;
        ev.offsetX = ev.touches[0].pageX - $(ev.touches[0].target).offset().left;
        ev.offsetY = ev.touches[0].pageY - $(ev.touches[0].target).offset().top;
      };

      var isInside = (x, y, obj) => {
        return !(y <= obj.top || (obj.top + obj.height <= y) || x <= obj.left || (obj.left + obj.width) <= x)
      };


      $scope.$on('regions-click', (event,payload) => {
        $scope.areaClick(payload);
      });

      $scope.areaClick = (ev) => {
        prepareOffsets(ev);

        $scope.list().each((comment) => {
          comment.selected = false;
          $scope.filterAreas(comment).each((area) => {
            var scaledArea = getScaledArea(width, area, comment.image_width);
            if( !!scaledArea  && isInside(ev.offsetX, ev.offsetY, scaledArea) ){
              comment.selected = true;
            }
          });
        });
        $rootScope.$broadcast('MarkupIsClicked');

        render();

        var el = $scope.list().find((el) => { return el.selected });

        $rootScope.$broadcast('changeSelection', el && el.id);
      };

      $scope.intersectWithRegions = (area, image_width) => {
        if(!clipRegions || clipRegions.length==0){return -1}

        var scaledArea = getScaledArea(width, area, image_width),
            ax1 = scaledArea.left, ay1 = scaledArea.top,
            ax2 = scaledArea.left+scaledArea.width, ay2 = scaledArea.top+scaledArea.height;

        for(var i=0; i<clipRegions.length; i++){

          var bx1 = clipRegions[i].left, by1 = clipRegions[i].top,
              bx2 = clipRegions[i].left+clipRegions[i].width, by2 = clipRegions[i].top+clipRegions[i].height;

          if( bx1 <= ax1 && ax2 <= bx2 && by1 <= ay1 && ay2 <= by2){
            return 1;
          }

          if( ax1 < bx2 && ax2 > bx1 && ay1 < by2 && ay2 > by1 ){
            return 0;
          }

        }
        return -1;

      };

    }
  }
}]);