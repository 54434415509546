import app from '../common_app.es6';


app.directive('regionsMapperSettings', [ '$rootScope', '$http', '$timeout',($rootScope, $http, $timeout) => {
  return {
    restrict: 'E',

    scope:{
      initConcept: '=',
      initRegionId: '='
    },

    template: require('./regions_mapper_settings_template.html'),

    link: ($scope, $element) => {

      const defaultClipMode = 'covered';

      $scope.disableRegions = false;
      $scope.name = null;
      $scope.placeholder = "No Region";

      $scope.resetSettings = (concept, regionId) => {
        $scope.regions = concept.regions;
        $scope.regionId = String(regionId);
        $scope.conceptId = concept.id;
        $scope.create_path = concept.create_region_path
      };
      $scope.resetSettings($scope.initConcept, $scope.initRegionId);

      $scope.selectRegion = (id) => {
        if(!$scope.regions){return null;}
        var region = $scope.regions.find((r) => { return String(r.id) == String(id)  });
        return region || null;
      };
      $scope.selected = { region: $scope.selectRegion($scope.regionId) };

      $scope.regionAdd = () => {
        $rootScope.$broadcast('region-hide');

        $http.post( $scope.create_path, {
          region: {
            concept_id: $scope.conceptId,
            name: $scope.name,
            data: $scope.regionAreas
          }
        }).then((response) => {
          $scope.regions = response.data.regions;
          $scope.selected.region = $scope.selectRegion(response.data.new_region_id);
          $scope.changeClipMode(defaultClipMode);
          $scope.broadcastChanges();
          $("#create-region-modal").modal('hide');
        });
      };

      $scope.regionUpdate = () => {
        $rootScope.$broadcast('region-hide');

        $http.put( $scope.selected.region.update_path, {
          region: { name: $scope.name }
        }).then((response) => {
          $scope.regions = response.data.regions;
          $scope.selected.region = $scope.selectRegion($scope.selected.region.id);
          $scope.broadcastChanges();
          $("#create-region-modal").modal('hide');
        });
      };

      $scope.modalFormClick = (event) => {
        event.preventDefault();
        event.stopPropagation();
      };

      $scope.cancelFormClick = (event) => {
        event.preventDefault();
        event.stopPropagation();
        $element.find('.modal.fade').modal('hide');
      };

      $scope.regionDelete = (backdrop) => {
        let selected = $scope.selected.region;

        if (selected && selected.id && backdrop) return;

        if (!!selected) {
          $rootScope.$broadcast('region-hide');
          if (selected.id) {
            $http.delete(selected.destroy_path).then((response) => {
              $scope.regions = response.data.regions;
              $scope.selected.region = null;
              $scope.broadcastChanges();
              $("#create-region-modal").modal('hide');
            });
          }
          else {
            $rootScope.$broadcast('region-hide', true);

            $scope.disableRegions = true;
            if ($scope.regionAreas) $scope.regionAreas.length = 0;
            $scope.selected.region = null;
            $scope.placeholder = "New Region";
            $scope.regionId = null;
            $("#create-region-modal").modal('hide');
          }
        }
      };

      $scope.changeClipMode = (v) => {
        var selected = $scope.selectRegion($scope.selected.region.id);
        if(!!selected){
          $scope.selected.region = selected;
          selected.clip_mode = v;
          $http.put(
            selected.update_path,
            {
              region: {
                clip_mode: selected.clip_mode
              }
            }
          ).then((response) => {});

          $scope.broadcastChanges();
        }
      };

      $scope.$on('region-create', () => {
        $scope.disableRegions = true;
        if ($scope.regionAreas) $scope.regionAreas.length = 0;
        $scope.selected.region = null;
        $scope.placeholder = "New Region";
        $scope.regionId = null;
        $scope.broadcastCreateRegion();
      });

      $scope.$on('regions-areas-changed', (event, regionAreas) => {
        $scope.regionAreas = regionAreas;
        $scope.selected.region = regionAreas[0];
        $scope.askName();
      });

      $scope.$on('region-settings-reset', (ev, data) => {
        $scope.disableRegions = false;
        $scope.resetSettings(data.concept, data.regionId);
        $scope.selected = {region: $scope.selectRegion($scope.regionId) };
        $scope.placeholder = "No Region";
        $scope.broadcastChanges();
      });

      $scope.broadcastChanges = () => {
        var selected = $scope.selected.region;
        if(!!selected) {
          $rootScope.$broadcast("region-settings-updated", {id: selected.id, mode: selected.clip_mode, regions: selected.data}, $scope.regions);
        }else{
          $rootScope.$broadcast("region-settings-updated", {id: null, mode: 'none', regions: []}, $scope.regions);
        }
      };

      $scope.broadcastCreateRegion = () => {
        $rootScope.$broadcast("region-settings-create", {id: null, mode: 'none', regions: []}, $scope.regions);
      };

      $scope.isClipMode = (v) => {
        var selected = $scope.selected.region;
        return !!selected && selected.clip_mode == v;
      };

      $scope.askName = () => {
        $scope.name = $scope.selected.region && $scope.selected.region.name;
        $("#create-region-modal").modal('show');
      };

    }

  }
}]);