import app from '../common_app.es6';
import './wysiwyg.es6';
import './custom_color_picker.es6';
import './wysiwig_remove_format/remove_format_button.es6';

app.factory('WysiwigCustomColorPicker', ['wysiwgGuiElements', (wysiwgGuiElements) => {
  const init = () => {
    wysiwgGuiElements['font-color'].attributes = [
      {name: 'title', value: 'Font Color'},
      {name: 'custom-color-picker', value: 'true'},
      {name: 'ng-model', value: 'fontColor'},
      {name: 'on-apply', value: 'setFontColor'},
      {name: 'type', value: 'button'}
    ];
    wysiwgGuiElements['hilite-color'].attributes = [
      {name: 'title', value: 'Highlight Color'},
      {name: 'custom-color-picker', value: 'true'},
      {name: 'ng-model', value: 'hiliteColor'},
      {name: 'on-apply', value: 'setHiliteColor'},
      {name: 'type', value: 'button'}
    ];
    wysiwgGuiElements['remove-format'].attributes = [
      { name: 'remove-format-button', value: 'true' }
    ];
  };
  return { init: init };
}]);

app.directive('wysiwigBetter', [ 'WysiwigCustomColorPicker', '$compile', (WysiwigCustomColorPicker, $compile) => {
  return {
    restrict: 'E',
    link: function ($scope, $element, $attrs) {
      WysiwigCustomColorPicker.init();

      let resultEl = "<wysiwyg";
      for(let attrName in $attrs.$attr){
        let elAttrName = $attrs.$attr[attrName],
            elAttrValue = $attrs[attrName];
        resultEl += ` ${elAttrName}=${elAttrValue} `;
      }
      resultEl += "></wysiwyg>";

      let compiledEl = $compile($(resultEl))($scope);
      compiledEl.insertAfter($element);
      $element.remove();
    }
  }
}]);