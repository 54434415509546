import app from '../common_app.es6';

app.directive('touchEnd', [ '$timeout', '$parse', ($timeout, $parse) => {
  return {
    restrict: 'A',
    link: function ($scope, $elem, $attrs) {
      var fn = $parse($attrs.touchEnd);
      var onTouchEnd = function(event) { $timeout(() => { fn($scope, { $event: event }); }); };
      $elem[0].addEventListener('touchend', onTouchEnd);
    }
  }
}]);