import app from '../../common_app.es6';
import {
  closePopupMessage, sendClosePopupMessage
} from '../../../approach-react/express/common/sidebar/components/bottomMenu/downloadIndicator/helper';

app.directive('downloadPopup', [ '$rootScope', '$timeout', ($rootScope, $timeout) => {
  return {
    restrict: 'E',
    scope: { x: '=', y: '=', show: '=', offsetX: '=', offsetY: '=', customText: '@' },
    template: require('./download_popup_template.html'),
    replace: true,
    link: ($scope, $element) => {
      $scope.messageSize = { width: 300, height: 74 };

      $scope.showList = () => {
        $scope.show = false;
        $rootScope.$broadcast(closePopupMessage);
        sendClosePopupMessage();
      };

      $scope.close = (event) => {
        event.preventDefault();
        event.stopPropagation();
        $scope.show = false;
      };

      $scope.placement = () => {
        return {
          top: ($scope.y - $scope.messageSize.height / 2) > 0 ? ($scope.y - $scope.messageSize.height / 2) : $scope.y,
          left: ($scope.offsetX - $scope.messageSize.width) > 0 ? ($scope.x - $scope.messageSize.width) : $scope.x,
          display: ($scope.show ? 'block' : 'none')
        };
      };

      $scope.setWidth = () => {
        return {
          width: $scope.messageSize.width
        };
      };

      const elementInNodesArray = (event, el) => {
        let curEl = event.target, bodyEl = $('body')[0], found = false;

        while (curEl != bodyEl) {
          if (!found) {found = (curEl == el);}
          curEl = curEl.parentNode;
        }

        return found;
      };

      const monitorClickOutside = (event) => {
        if (!elementInNodesArray(event, $element[0])) {
          $timeout(() => { $scope.show = false; });
        }
      };

      $('body')[0].addEventListener('mousedown', monitorClickOutside);

      $scope.$on("$destroy", () => {
        $('body')[0].removeEventListener('mousedown', monitorClickOutside);
      });
    }
  };
} ]);
