import app from '../common_app.es6'
import { isEmpty } from 'lodash'

app.filter('napr', function() {
  const percentWithNA = function(input) {
    const parsed = parseFloat(input);
    if(isNaN(parsed) || parsed==0){
      return 'N/A'
    }
    return Math.round(parsed)+"%";
  };
  return percentWithNA;
});

app.filter('nap', function() {
  const percentWithNA = function(input) {
    const parsed = parseFloat(input);
    if(isNaN(parsed) || parsed==0){
      return 'N/A'
    }
    return parsed+"%";
  };
  return percentWithNA;
});

app.filter('nar', function() {
  const withNA = function(input) {
    const parsed = parseFloat(input);
    if(isNaN(parsed) || parsed==0){
      return 'N/A'
    }
    return Math.round(parsed);
  };
  return withNA;
});

app.filter('na', function() {
  const withNA = function(input) {
    const parsed = parseFloat(input);
    if(isNaN(parsed) || parsed==0){
      return 'N/A'
    }
    return parsed;
  };
  return withNA;
});

app.filter('smartValueOrNa', ['$filter', ($filter) => {
  return (input, isNoFilter) => $filter(isNoFilter ? 'na' : 'number')(input)
}])

app.filter('smartPercentOrNa', ['$filter', ($filter) => {
  return (input, isNoFilter) => $filter(isNoFilter ? 'nap' : 'percent')(input)
}])