import app from '../common_app.es6';
import { addOne } from 'stickyfilljs';

app.directive('sticky', [ () => {
  return {
    restrict: 'A',
    link: function ($scope, $element) {
      addOne($element);
    }
  }
}]);